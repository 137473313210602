<template>
    <div id="map">
    </div>
    <div id="header">
        <a href="/"><h1>東京通り名マップ</h1></a>
        <span id="subtitle">- Tokyo Street Map -</span>
        <span id="menu"><i class="fas fa-th"></i></span>
    </div>
    <div id="route-bg">
        <i id="close-button" class="far fa-times-circle"></i>
    </div>
    <div id="route">
        <span>{{ routeNameJp }}</span><span id="route-en">{{ routeNameEn}}</span>
    </div>
    <div id="menu-contents">
        <span id="info"><i class="far fa-question-circle"></i></span>
        <div id="all-route-button">すべて表示</div>
    </div>
    <div id="info-contents">
        <div id="close-button-area">
            <i id="info-close" class="far fa-times-circle"></i>
        </div>
        <h2>東京通り名マップ</h2>
        <h3>作者：石﨑健太（<a href="https://www.kentaishizaki.com/">https://www.kentaishizaki.com/</a>）</h3>
        <p>東京都(23区および武蔵野市・三鷹市)の主要な道路の名称を地図上に表示するアプリです。</p>
        <p>（2021年9月時点での情報に基づいております。）</p>
    </div>
    <div id="modal-bg"></div>
</template>

<script>
import  "leaflet/dist/leaflet.css"
import  L from "leaflet"

export default {
    data() {
        return {
            routeNameJp: "",
            routeNameEn: "",
        }
    },
    mounted() {
        const elements = {
            "route": document.getElementById("route"),
            "route_bg": document.getElementById("route-bg"),
            "close_button": document.getElementById("close-button"),
            "menu_button": document.getElementById("menu"),
            "menu_contents": document.getElementById("menu-contents"),
            "all_route_button": document.getElementById("all-route-button"),
            "route_buttons": null,
            "info_button": document.getElementById("info"),
            "info_contents": document.getElementById("info-contents"),
            "info_close_button": document.getElementById("info-close"),
            "subtitle": document.getElementById("subtitle"),
            "modal_bg": document.getElementById("modal-bg"),
        }

        const map = L.map("map", { center: L.latLng(35.680959106959, 139.76730676352), zoom: 14}).addLayer(
            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'})
        )

        let routes = {
            "route_Uchibori": [
                L.polyline([
                    [35.677717863943755,139.75340222022896],
                    [35.676707018373605,139.75587034039359],
                    [35.67948735646837,139.7576165524655],
                    [35.682753962107526,139.7597476715024],
                    [35.68473829556693,139.76056743317747],
                    [35.68568281074809,139.76124066837895],
                    [35.687245861143154,139.76161871893805],
                    [35.689331425612124,139.76085517907018],
                    [35.69009362290335,139.75991162979312],
                    [35.690358733300585,139.7587844709327],
                    [35.69069012004755,139.7563771452053],
                    [35.691774699682654,139.75599462527043],
                    [35.69273155819752,139.75540299440263],
                    [35.693805293997954,139.75292214190165],
                    [35.69450117450906,139.75181538409834],
                    [35.69545385823892,139.75141756331868],
                    [35.69514320172253,139.74936109716214],
                    [35.694304880956594,139.74648721839526],
                    [35.68911468445751,139.7454158617445],
                    [35.683942257888845,139.74412002528356],
                    [35.68349070487275,139.74410472447312],
                    [35.682513284934785,139.7442883265138],
                    [35.68182972817425,139.74461474358714],
                    [35.679809862237235,139.74582399436486],
                    [35.67937485749803,139.74722146735303],
                    [35.67851702085588,139.74815854795384],
                    [35.67692010686447,139.74951146426326],
                    [35.67739655514325,139.75185758683438],
                    [35.677717863943755,139.75340222022896],
                ], {color: "#3a3fbdaa", weight: 20})
            , "内堀通り", "Uchibori-dori Ave.", [35.677717863943755,139.75340222022896], "#3a3fbd", "うちぼり"],

            "route_Sotobori": [
                L.polyline([
                    [35.68037746163765,139.76906633967064],
                    [35.68180617704478,139.7699939211832],
                    [35.68248421241934,139.77043606973893],
                    [35.68360205442039,139.77096239937646],
                    [35.68429245838611,139.77124827128546],
                    [35.685301737724615,139.77129782240524],
                    [35.68656776182033,139.77065390391124],
                    [35.68755843736375,139.76999068089742],
                    [35.68932898442448,139.7684603021761],
                    [35.68958902895889,139.76688991227184],
                    [35.69219085181797,139.76743915245277],
                    [35.69600104794084,139.76746685000398],
                    [35.69670063106163,139.76771079400532],
                    [35.6986822743751,139.76898302049108],
                    [35.69962453267401,139.7670496394643],
                    [35.70063428692721,139.76381388085838],
                    [35.70090667589035,139.76318496250587],
                    [35.70156597711268,139.76259416033008],
                    [35.70178574297266,139.76201860476817],
                    [35.70194203933342,139.76108632249418],
                    [35.701926808636614,139.7577365524287],
                    [35.702387901591365,139.7553836421041],
                    [35.70280148098699,139.75376126740775],
                    [35.70323199143469,139.74888729988515],
                    [35.70323818192156,139.74661469819364],
                    [35.70303749019389,139.74558668123427],
                    [35.702625822096024,139.74467570249067],
                    [35.70129028064348,139.7432856002755],
                    [35.7004235794888,139.74268446952578],
                    [35.697830972537474,139.740508297735],
                    [35.696869401169934,139.73967070139724],
                    [35.694263241130685,139.73771732498926],
                    [35.693118988245644,139.73653702617878],
                    [35.69217712262143,139.7351842339848],
                    [35.69090834176268,139.73314640581077],
                    [35.690222303288536,139.73238385011382],
                    [35.68959741333963,139.73065036604817],
                    [35.6858749467128,139.72945134522297],
                    [35.68411218034096,139.72923427582316],
                    [35.681309920666266,139.73074809054287],
                    [35.679958256232,139.73236359508587],
                    [35.67891110006531,139.73292635201415],
                    [35.67853646174909,139.73355908189615],
                    [35.67824737658893,139.73646505583838],
                    [35.67677723212876,139.73726399222812],
                    [35.67599697079868,139.73778999657475],
                    [35.67456871904341,139.73851038599616],
                    [35.67348498464716,139.73910907811467],
                    [35.67193449868712,139.74141409305798],
                    [35.67095768624,139.74286626991216],
                    [35.67032300775959,139.7458143932878],
                    [35.67024385308589,139.74739597411497],
                    [35.670423450686414,139.74932846852357],
                    [35.66742941608587,139.7584085018109],
                    [35.66840794288585,139.75888495501692],
                    [35.672525888687844,139.76326792131545],
                    [35.6732163884992,139.76425513242793],
                    [35.677262129759995,139.76706770644296],
                    [35.68037746163765,139.76906633967064],
                ], {color: "#161df0aa", weight: 20})
            , "外堀通り", "Sotobori-dori Ave.", [35.68037746163765,139.76906633967064], "#161df0", "そとぼり"],

            "route_Meiji": [
                L.polyline([
                    [35.6465644893522,139.73595555488578],
                    [35.64681217905658,139.7342633806071],
                    [35.64736307240429,139.7330441743269],
                    [35.64740150667686,139.73219283198637],
                    [35.64719225312224,139.73046912654024],
                    [35.647145277765404,139.72924466502252],
                    [35.64678228540765,139.7279623963368],
                    [35.646662711080026,139.72545566603117],
                    [35.646786555870136,139.72447819888555],
                    [35.6474271294002,139.72352700776946],
                    [35.64766627552768,139.7228438318266],
                    [35.64835856914226,139.72007167221022],
                    [35.64880269192203,139.71909420510906],
                    [35.64885820709175,139.7184110291637],
                    [35.64833307197242,139.7151328923569],
                    [35.64830317898533,139.7142762948463],
                    [35.64896287975141,139.71127584255305],
                    [35.65053034193943,139.7095981625796],
                    [35.65134169618801,139.70908315301438],
                    [35.6522128252421,139.70895702822781],
                    [35.65297292029514,139.70883615864977],
                    [35.65350669058479,139.7085313570716],
                    [35.65408315848188,139.70782716034591],
                    [35.65459557089939,139.70718602598822],
                    [35.65527024224508,139.70656065721448],
                    [35.65583388734258,139.70591952289783],
                    [35.658045491824865,139.70314200139558],
                    [35.65955312880779,139.70242621473062],
                    [35.663562467843875,139.70212466556063],
                    [35.664326724448884,139.70231910795678],
                    [35.66525748518099,139.70297600790389],
                    [35.66679745781798,139.7042087035909],
                    [35.66848033644935,139.70537416291734],
                    [35.67016203226909,139.70673452591177],
                    [35.67084937975896,139.7071549419038],
                    [35.6719849843916,139.70809036745084],
                    [35.6724118992402,139.7081429194634],
                    [35.67414560950455,139.70735562268317],
                    [35.67487561273802,139.70687214433553],
                    [35.67758139326874,139.7061027770817],
                    [35.679445716949914,139.70471742847974],
                    [35.68012444704472,139.70452824129347],
                    [35.68315948286529,139.70456089766617],
                    [35.68375707933737,139.70439798647453],
                    [35.684499800139236,139.70385144570923],
                    [35.68785460548867,139.70286827375753],
                    [35.690859665014145,139.70485398895005],
                    [35.693994456744406,139.70672583256487],
                    [35.70336974267123,139.70845097810798],
                    [35.708415752244065,139.70912805776948],
                    [35.71274688287838,139.71039259983297],
                    [35.71739539103227,139.71279054292177],
                    [35.71895303822566,139.71346221369913],
                    [35.719908761293,139.7132152193285],
                    [35.72442074105274,139.71134275928853],
                    [35.72506922416156,139.71133224892787],
                    [35.727487738190206,139.71196396312416],
                    [35.729610823231084,139.7121896081817],
                    [35.730628440128015,139.7126827228666],
                    [35.731353660406626,139.71291920684644],
                    [35.73347548422191,139.7167386073554],
                    [35.73671972989142,139.72028078809012],
                    [35.738631408272056,139.7223836934586],
                    [35.73997420930998,139.72383997153156],
                    [35.742042959851815,139.72622057703836],
                    [35.74362714925293,139.727359796746],
                    [35.74419870072009,139.72809026949767],
                    [35.74829921619785,139.73362613244421],
                    [35.75082977490901,139.7374248174075],
                    [35.75212108665343,139.7364166859213],
                    [35.752662723252186,139.7365901075126],
                    [35.75296979117216,139.737446705077],
                    [35.75340906682776,139.73800901145216],
                    [35.75359671782873,139.73839264105584],
                    [35.754645849367265,139.73937536343942],
                    [35.7554305565985,139.74115687611481],
                    [35.7558996713765,139.74162984404688],
                    [35.75033606151945,139.7488028779478],
                    [35.74519513595067,139.75822873761865],
                    [35.73924054016942,139.76866086717246],
                    [35.738080971164536,139.77070315756828],
                    [35.73699329178473,139.77693791487536],
                    [35.73435116889155,139.78601087265247],
                    [35.73414214334581,139.78649960622647],
                    [35.7324128175683,139.78778388901992],
                    [35.73003821836281,139.7917744085671],
                    [35.72874682726675,139.79363255291787],
                    [35.72886717673046,139.79681112206288],
                    [35.72910907071254,139.80149543381935],
                    [35.728155010180245,139.80852259870596],
                    [35.72755628262556,139.81311798185652],
                    [35.72243752870203,139.8183253983583],
                    [35.717417840623924,139.82317667956625],
                    [35.7118063486279,139.82864923290754],
                    [35.71136257632395,139.82891199293954],
                    [35.70520408384944,139.82593400183816],
                    [35.70172839618016,139.82539469255494],
                    [35.68541514509174,139.8266819400211],
                    [35.68376936445216,139.8268364386156],
                    [35.681290878786115,139.8276271896816],
                    [35.6807530255232,139.82765346569886],
                    [35.67360472648237,139.82659478154983],
                    [35.66993238912937,139.82578592669097],
                    [35.666465389236706,139.82563272757716],
                    [35.66596586139677,139.82537522281496],
                    [35.66454698165556,139.82394195136044],
                    [35.664017554715855,139.82373699858056],
                    [35.66220255625072,139.82371738413144],
                    [35.65773790514983,139.82405525349282],
                    [35.656124407704226,139.82416825675887],
                    [35.65509161716122,139.82430718759406],
                    [35.646824006983024,139.82548688093016],
            ], {color: "#db431daa", weight: 20})
            , "明治通り", "Meiji-dori Ave.", [35.6465644893522,139.73595555488578], "#db431d", "めいじ"],

            "route_Yamate": [
                L.polyline([
                    [35.620548832517414,139.74625084248612],
                    [35.61791379471484,139.74380290978422],
                    [35.61707413236894,139.7424188333889],
                    [35.61690200049938,139.7416958084143],
                    [35.61686841374576,139.74106574380517],
                    [35.6169675160903,139.73780177565618],
                    [35.61675759883855,139.7372181911851],
                    [35.61617402600314,139.73683085635219],
                    [35.61608585995154,139.73665526457023],
                    [35.61624539843095,139.73584960816265],
                    [35.61648050721068,139.73553974032757],
                    [35.6178955545752,139.73467642583694],
                    [35.6180970722676,139.73399471656126],
                    [35.61786616652428,139.7327139294465],
                    [35.61866787526979,139.72968521620146],
                    [35.62076385733293,139.72818246941938],
                    [35.62096116966761,139.7276660229993],
                    [35.620873008897064,139.726886188952],
                    [35.62090239582871,139.72623030196928],
                    [35.621145887149765,139.72554859270122],
                    [35.62139357584175,139.72514060003505],
                    [35.62183017775713,139.72461382468782],
                    [35.6232612682461,139.72223066558763],
                    [35.62391195697971,139.72099119417362],
                    [35.62858254217541,139.7117715455338],
                    [35.628880580420315,139.7112964148231],
                    [35.62930454839227,139.71079546179465],
                    [35.634914848051054,139.70691413119494],
                    [35.63603837631955,139.70614470151008],
                    [35.63992307262326,139.703037468199],
                    [35.64267235329673,139.70074216220928],
                    [35.643650245876756,139.6999210123788],
                    [35.64572553087751,139.6973819174343],
                    [35.64919487052752,139.6915707929495],
                    [35.65067032412351,139.68989898194457],
                    [35.65098816394564,139.68971461993237],
                    [35.65372481086082,139.68954296527215],
                    [35.65429132405343,139.68972888599853],
                    [35.656861698659625,139.6908682840858],
                    [35.657478544160384,139.69101805355967],
                    [35.65797369559807,139.69079081714312],
                    [35.66023289321343,139.68894128337502],
                    [35.66206598789407,139.68758059673075],
                    [35.663170796485915,139.6877324048694],
                    [35.66671021128688,139.68796329178883],
                    [35.669730267958826,139.6878467402728],
                    [35.671581649871044,139.68768178868447],
                    [35.67720889366777,139.68787473461498],
                    [35.678905074226535,139.68851740713035],
                    [35.68117888760854,139.68885758525562],
                    [35.68168228193878,139.6887801183011],
                    [35.682374443956704,139.6881862049006],
                    [35.68445272974948,139.6863170479836],
                    [35.68627559474308,139.68487639953184],
                    [35.688898026433165,139.68223838025312],
                    [35.68985438041426,139.6817374272157],
                    [35.690668111173906,139.68167028918947],
                    [35.69211576795614,139.6819338338512],
                    [35.6970037230204,139.68288603682186],
                    [35.700204573726964,139.68308554152077],
                    [35.702141158530054,139.68236508065092],
                    [35.703143485644574,139.68232376493995],
                    [35.706105113810224,139.68267760404058],
                    [35.714227393992886,139.68640100867174],
                    [35.717731183023034,139.68804684023712],
                    [35.71900166504514,139.68923983152456],
                    [35.72249186561113,139.69259013241708],
                    [35.72439090907629,139.6943889599031],
                    [35.72881213541268,139.69726785062107],
                    [35.73311751892493,139.69938665259974],
                    [35.73473711822068,139.70018753976592],
                    [35.7365453641203,139.70211296436048],
                    [35.7394593568397,139.7053074191308],
                    [35.7424498741388,139.70782655828725],
                    [35.74335947703699,139.70814159060092],
                    [35.749529103246715,139.708405690041],
                    [35.75336905335121,139.70861178277653],
            ], {color: "#8aeb9aaa", weight: 20})
            , "山手通り", "Yamate-dori Ave.", [35.620548832517414,139.74625084248612], "#8aeb9a", "やまて"],

            "route_KyuYamate": [
                L.polyline([
                    [35.64661425889345,139.70244482900466],
                    [35.647400439526365,139.70189453033493],
                    [35.64826595054775,139.70046552896974],
                    [35.648828527687684,139.69914303701034],
                    [35.64946322533618,139.69829096163664],
                    [35.6523829219899,139.6950316349346],
                    [35.653976798076854,139.69330085688307],
                    [35.65586632942828,139.69185410391697],
                    [35.65779187456614,139.69090439493698],
            ], {color: "#ff7a7aaa", weight: 20})
            , "旧山手通り", "Kyu-yamate-dori Ave.", [35.64661425889345,139.70244482900466], "#ff7a7a", "きゅうやまて"],

            "route_DaiichiKeihin": [
                L.polyline([
                    [35.666693819022655,139.76043572318565],
                    [35.663628429642145,139.75793282486723],
                    [35.66019323960886,139.75515607368243],
                    [35.65278417477212,139.75412698157083],
                    [35.651066277360314,139.75382018474124],
                    [35.64908922869324,139.75306686800138],
                    [35.648892399145254,139.75277081997763],
                    [35.6480881365341,139.7498978210633],
                    [35.64636746268616,139.7462057880948],
                    [35.64515721304264,139.74421081606317],
                    [35.64394117624184,139.74294050087747],
                    [35.642436414461145,139.7419500856672],
                    [35.641303450106534,139.74132569345284],
                    [35.64023171229583,139.7408843127463],
                    [35.637603241728726,139.7394453140887],
                    [35.632258301363606,139.7376993351893],
                    [35.62904077033683,139.73751914852232],
                    [35.62735618900935,139.73761586791784],
                    [35.626147244066715,139.73786195988941],
                    [35.62488331803482,139.73823813796514],
                    [35.623708285352265,139.73799072061865],
                    [35.62264227209519,139.73853344586064],
                    [35.61975651267781,139.74042747642363],
                    [35.618859511690346,139.74079888212327],
                    [35.6182644225632,139.74087423980404],
                    [35.61442641211673,139.7412435394233],
                    [35.60964868479118,139.74245416167128],
                    [35.60679791240631,139.74315351860741],
                    [35.60626837848962,139.74299203786302],
                    [35.60333209690182,139.74055421938021],
                    [35.60254870163392,139.74003748098403],
                    [35.60145642325527,139.73952247554632],
                    [35.59483010690258,139.7364707126319],
                    [35.59260509308872,139.73631344754403],
                    [35.59138773193487,139.736144133607],
                    [35.587266850893414,139.73582531994157],
                    [35.58295341461569,139.73656018559927],
                    [35.58199909154098,139.7365494202179],
                    [35.57370228773189,139.7338571064688],
                    [35.57057281833829,139.73165828634228],
                    [35.56401912952656,139.72676737493816],
                    [35.56024741212043,139.72393402589034],
                    [35.55958620399619,139.72328272020593],
                    [35.55442616381647,139.71943248620414],
                    [35.55261442147365,139.7181167972435],
                    [35.549751484133026,139.7159613025152],
                    [35.54508896935744,139.71242338116576],
                    [35.542630116212166,139.7104569009208],
                    [35.54064440612417,139.70910804006814],
                    [35.53635368421098,139.70830505210608],
            ], {color: "#b0073aaa", weight: 20})
            , "第一京浜", "Daiichikeihin Ave.", [35.666693819022655,139.76043572318565], "#b0073a", "だいいちけいひん"],

            "route_Chuo": [
                L.polyline([
                    [35.666693819022655,139.76043572318565],
                    [35.667470776735,139.76109650372553],
                    [35.67121362954167,139.76503344795742],
                    [35.67506218277616,139.76906768717436],
                    [35.683544961454416,139.77432378879644],
                    [35.684572697285404,139.77460314099483],
                    [35.693113215338144,139.77113373563475],
                    [35.69530760630656,139.770262316483],
                    [35.69756870784745,139.77114486274843],
                    [35.698510480047375,139.7712774535371],
                    [35.70578180169713,139.77206006727556],
                    [35.70720332542571,139.7728804445626],
                    [35.710204201953815,139.77361713294204],
                    [35.7114550836924,139.7743565946812],
                    [35.711658558593925,139.77473043368275],
                    [35.71140171310485,139.7768378998618],
            ], {color: "#e88133aa", weight: 20})
            , "中央通り", "Chuo-dori Ave.", [35.666693819022655,139.76043572318565], "#e88133", "ちゅうおう"],

            "route_Showa": [
                L.polyline([
                    [35.66742899965226,139.75840819815994],
                    [35.666708490993656,139.7604518808747],
                    [35.666481018037075,139.76127064827187],
                    [35.66649135772883,139.76261122084975],
                    [35.667146202217225,139.7640663360238],
                    [35.669572585201834,139.76706342715397],
                    [35.67379700790222,139.7708493674803],
                    [35.683586330602395,139.77692200285082],
                    [35.68712784584942,139.77668134731107],
                    [35.69170264137264,139.77478677283898],
                    [35.69657802528002,139.77544382419842],
                    [35.70060781155479,139.77554761590795],
                    [35.71112192909875,139.77663797553953],
                    [35.712182870031086,139.77802945592748],
                    [35.712672000372535,139.77848338390595],
                    [35.716166954807065,139.78094517683462],
                    [35.7198671031304,139.78387142788142],
                    [35.72466225944622,139.78756197839857],
                    [35.728286994787695,139.79016625454904],
                    [35.730041722287424,139.79176536382738],
            ], {color: "#ebc015aa", weight: 20})
            , "昭和通り", "Showa-dori Ave.", [35.66742899965226,139.75840819815994], "#ebc015", "しょうわ"],

            "route_Asakusa": [
                L.polyline([
                    [35.712167934304716,139.7779799443124],
                    [35.71030351890042,139.78823442630187],
                    [35.709664088362416,139.79140328190647],
                    [35.71143600976218,139.79200145650225],
                    [35.71068955956534,139.79788352891296],
                    [35.70963386933157,139.7997451286285],
                    [35.70850402600705,139.80224667265156],
                    [35.7090356137775,139.81366667163024],
                    [35.70800230860078,139.81782868765606],
                    [35.70764204105359,139.81918436765432],
                    [35.70501116816851,139.82587264325502],
            ], {color: "#c525e6aa", weight: 20})
            , "浅草通り", "Asakusa-dori Ave.", [35.712167934304716,139.7779799443124], "#c525e6", "あさくさ"],

            "route_Kitahon": [
                L.polyline([
                    [35.75342143362887,139.73808321369188],
                    [35.754714140671766,139.7371207903425],
                    [35.756106063755325,139.73732589459837],
                    [35.75905931132225,139.73752331317314],
                    [35.76098953113814,139.73762115858767],
                    [35.76207120798885,139.73710767422358],
                    [35.763977592248736,139.73605881430478],
                    [35.76953825412461,139.73427633861945],
                    [35.77255924244035,139.73321727428947],
                    [35.77318568016575,139.73311970087764],
                    [35.77634073118125,139.7332860150384],
                    [35.77708071805334,139.73316723003836],
                    [35.779105483700704,139.73175409052465],
                    [35.77951504443381,139.73135531248545],
                    [35.7815960355517,139.72897359339208],
                    [35.78203311601743,139.72828209535555],
                    [35.782294674820065,139.72756090114035],
                    [35.78375547465674,139.7206512067619],
                    [35.78511262084882,139.72140639651025],
                    [35.786007389090685,139.72183911295178],
                    [35.786640603605164,139.7226493957022],
                    [35.78837107733881,139.72343652659356],
            ], {color: "#f2a5eaaa", weight: 20})
            , "北本通り", "Kitahon-dori Ave.", [35.75342143362887,139.73808321369188], "#f2a5ea", "きたほん"],

            "route_KanPachi": [
                L.polyline([
                    [35.78375547465674,139.7206512067619],
                    [35.78400326186602,139.71958214245933],
                    [35.78596260161784,139.71771876698813],
                    [35.78625856137367,139.71726483885016],
                    [35.78646160289476,139.71655212926498],
                    [35.78656484414407,139.71431214168865],
                    [35.786526989033746,139.71363761297107],
                    [35.785068381838,139.7058038625421],
                    [35.78418198007131,139.70381458310567],
                    [35.78227463063631,139.70118839471377],
                    [35.78125906408803,139.69850291206012],
                    [35.780089170471015,139.69090931697906],
                    [35.77774073786544,139.68182946815546],
                    [35.77650147657543,139.67729247102466],
                    [35.776047156541864,139.6766433962432],
                    [35.771953930848824,139.671860164014],
                    [35.77096240070409,139.67104661558972],
                    [35.76725178706218,139.66822059210108],
                    [35.765635237620614,139.66694614072367],
                    [35.7639349163163,139.66465930153458],
                    [35.760445045507694,139.65751722363098],
                    [35.75824762507047,139.6531755734045],
                    [35.75348925513359,139.64422243674937],
                    [35.75139227968579,139.64034189133622],
                    [35.747566994762636,139.63326114479298],
                    [35.74473884311762,139.6288029036698],
                    [35.74094122561844,139.6229504357259],
                    [35.7385240536851,139.62019505656247],
                    [35.733541253851726,139.6146425186497],
                    [35.72982089749662,139.61452975539692],
                    [35.723373577451525,139.61427876886853],
                    [35.71908395240804,139.61409314586535],
                    [35.70810542700214,139.61506240624874],
                    [35.699938020512846,139.61721676782292],
                    [35.696176493251684,139.61783585504133],
                    [35.692793799476604,139.61682223328603],
                    [35.684692945493836,139.61559244747423],
                    [35.68345456850918,139.61567272115826],
                    [35.68039262514522,139.6146299501488],
                    [35.67726678590098,139.61430397155186],
                    [35.67375337290896,139.6135699671995],
                    [35.66851193079947,139.61330189522494],
                    [35.655540748494175,139.61546239121526],
                    [35.645435045509856,139.61807103987712],
                    [35.638751649590375,139.61993409341198],
                    [35.638317230469596,139.62017166319083],
                    [35.63260901738377,139.62449140560682],
                    [35.63145580641593,139.62511551026273],
                    [35.62729554296149,139.6264541183432],
                    [35.62389716548452,139.6275557061631],
                    [35.62322472423706,139.62800114956087],
                    [35.62274194242342,139.62852719704597],
                    [35.621167172288395,139.63119575702888],
                    [35.6191006854059,139.6345514165713],
                    [35.61856614922512,139.6351319090295],
                    [35.60783290055029,139.640177478959],
                    [35.60717412073055,139.64072473800172],
                    [35.60654637784573,139.64208652214518],
                    [35.60507188774449,139.64747291522713],
                    [35.60380666866736,139.65206165284008],
                    [35.60234424459418,139.6565916601421],
                    [35.60072955476167,139.66448971490354],
                    [35.599757546875665,139.6688926855693],
                    [35.59915166768616,139.66994799187717],
                    [35.5956029242212,139.6730293334856],
                    [35.5935349162899,139.67472288918293],
                    [35.582669199185915,139.68176495052353],
                    [35.57456921141865,139.68433611254022],
                    [35.573713475849985,139.68497246022412],
                    [35.570081239788195,139.69027116580855],
                    [35.56841272136052,139.69242263121055],
                    [35.56332483693819,139.699676300477],
                    [35.56016605542561,139.7077793871524],
                    [35.55921116857017,139.71661060793326],
                    [35.55766547736043,139.7237852176802],
                    [35.55731689971455,139.72469307379095],
                    [35.553616503271584,139.72985342528273],
                    [35.553025035512505,139.73224151363934],
                    [35.552358459396615,139.74036096285863],
                    [35.55178259833414,139.74553877672292],
                    [35.55137298352176,139.74842253826057],
                    [35.55049404348597,139.75328681956194],
                    [35.55003153244813,139.75484799269793],
                    [35.549417147977664,139.75595948000932],
                    [35.548857984991415,139.75651522365752],
                    [35.5467244756105,139.75814018836604],
                    [35.545871894432494,139.75923894868492],
                    [35.54547839238889,139.76013832013882],
                    [35.54493160672432,139.76163803402915],
                    [35.54356088837527,139.76499272385627],
                    [35.54247162958741,139.76713517526946],
                    [35.541407799717646,139.76964802543284],
                    [35.54075628488731,139.7712651255954],
                    [35.53789459804618,139.77763209987847],
                    [35.53775306151854,139.77920175764143],
                    [35.538274328922,139.78039809142467],
                    [35.5396689614919,139.78299014784375],
                    [35.53981049462321,139.78391921553452],
                    [35.539793234489196,139.7858706818829],
                    [35.53991405528997,139.78688035366804],
                    [35.54055958046919,139.78871303514427],
                    [35.54219990074448,139.79215791409842],
            ], {color: "#de66d2aa", weight: 20})
            , "環八通り", "Kanpachi-dori Ave.", [35.78375547465674,139.7206512067619], "#de66d2", "かんぱち"],

            "route_Wangan": [
                L.polyline([
                    [35.54219990074448,139.79215791409842],
                    [35.55633874671315,139.78182851120732],
                    [35.559606169457226,139.77815958838391],
                    [35.561918902699006,139.7735716439887],
                    [35.56487413701764,139.76774244039757],
                    [35.56676595492313,139.7639805496683],
                    [35.56769108500423,139.76222065296884],
                    [35.56911483742641,139.76052365165833],
                    [35.570203427291275,139.7598716565011],
                    [35.572480328036235,139.7590816101507],
                    [35.578423853735075,139.7572200082834],
                    [35.58447919474986,139.7554001511121],
                    [35.58626598170201,139.75526376118842],
                    [35.59533708344283,139.75529580091336],
                    [35.60701365105203,139.7553550486075],
                    [35.609459291800874,139.75538950380155],
                    [35.611188991767165,139.75586011683185],
                    [35.61245236649134,139.7568454628448],
                    [35.61385919248238,139.75853182625576],
                    [35.615178318446056,139.7602966251029],
                    [35.622009807857665,139.76918095037684],
                    [35.62520132928347,139.77337419742344],
                    [35.62683049213224,139.77624823922315],
                    [35.632312187220634,139.7863197572185],
                    [35.638119545260466,139.797071731831],
                    [35.640937120793,139.80258187690424],
                    [35.64427492089558,139.81271026362322],
                    [35.645544916762915,139.81684674453618],
                    [35.64579987495166,139.8185478145342],
                    [35.64649073057818,139.82553068072684],
                    [35.64758094734714,139.83751069023106],
                    [35.64685721905957,139.85044340967352],
                    [35.646315135058344,139.85766342560794],
                    [35.645809843067255,139.85949076103088],
                    [35.64306427390535,139.8669846975506],
                    [35.64118662524011,139.872051798671],
                    [35.64005289904462,139.87518611543646],
            ], {color: "#21c2deaa", weight: 20})
            , "湾岸道路", "Wangan-doro Ave.", [35.54219990074448,139.79215791409842], "#21c2de", "わんがん"],

            "route_KanNana": [
                L.polyline([
                    [35.64306427390535,139.8669846975506],
                    [35.64672404650683,139.870972157089],
                    [35.64850138598631,139.87196939576415],
                    [35.65032383086208,139.87218169008645],
                    [35.660291114684796,139.87288332154662],
                    [35.66765493831144,139.87340158565894],
                    [35.674559865385376,139.87739452220342],
                    [35.683149400236175,139.88144450976927],
                    [35.68857005203168,139.88303922604177],
                    [35.69065796240345,139.88285390762962],
                    [35.697429353050254,139.88207452427082],
                    [35.70251406375846,139.8814784308306],
                    [35.71859469481204,139.8776120131049],
                    [35.719654667219075,139.87704353885215],
                    [35.72459907385577,139.87241910080184],
                    [35.73430678558152,139.86639241634816],
                    [35.742176996600364,139.86161466090607],
                    [35.748492991501564,139.85779653537384],
                    [35.74966986124669,139.85709798649782],
                    [35.75296608157943,139.85603401628475],
                    [35.757364273924765,139.85454906632629],
                    [35.763098583702316,139.85174044382734],
                    [35.76830610187274,139.84924309708651],
                    [35.77029528482514,139.84826422586093],
                    [35.774493382267906,139.84512316476025],
                    [35.775208656042196,139.84451614983914],
                    [35.77593174049079,139.84347555290128],
                    [35.77647502664516,139.84194838054418],
                    [35.776639184319976,139.84058500588685],
                    [35.777331462174715,139.83382102734402],
                    [35.77742917405115,139.83213969248084],
                    [35.77818991746011,139.819423670962],
                    [35.77858494762066,139.81571651255229],
                    [35.77868802688042,139.80313294135522],
                    [35.77854749522841,139.79762394585032],
                    [35.77810603377981,139.78477447506395],
                    [35.77800637263584,139.7821469900307],
                    [35.7782085221679,139.77016702821015],
                    [35.77826670501547,139.7605384166152],
                    [35.778694795909544,139.74877573656772],
                    [35.77860099398191,139.74792302518188],
                    [35.778354763394205,139.74733528062382],
                    [35.77379453864913,139.7406353173144],
                    [35.769540947896814,139.73425839787365],
                    [35.767630871892436,139.72758249416893],
                    [35.76659075025256,139.7238659469051],
                    [35.766473479981926,139.72227614606152],
                    [35.76637966363374,139.7215438741705],
                    [35.76627412009742,139.72125963699807],
                    [35.76522322169447,139.7192563447236],
                    [35.76453913093455,139.71725223215242],
                    [35.76353414532865,139.71342777472387],
                    [35.76152507871551,139.70679298382004],
                    [35.761302250869676,139.70555004862513],
                    [35.75801010620097,139.69265539056119],
                    [35.75756052074401,139.69184603739473],
                    [35.75515892632801,139.68807156451294],
                    [35.745513055324665,139.67475372665785],
                    [35.740845036535106,139.66838369222057],
                    [35.73840660217533,139.66655002359823],
                    [35.73485242951668,139.6625928010768],
                    [35.72825791733662,139.65509380403716],
                    [35.72747573569136,139.65425554540911],
                    [35.72675612179404,139.65393758522862],
                    [35.725259666133,139.65383487463532],
                    [35.71424645755249,139.65373969706422],
                    [35.713542370605836,139.65375414976472],
                    [35.709674197920975,139.6542026729088],
                    [35.704544518047115,139.65480713584088],
                    [35.70246899476973,139.65482698419532],
                    [35.697995497035635,139.65467693205596],
                    [35.695543179864515,139.65432223952428],
                    [35.693620919485056,139.65428024503922],
                    [35.691741389916345,139.6546155587247],
                    [35.68938351713953,139.65500329092606],
                    [35.68516642205644,139.6565456142739],
                    [35.68471642535748,139.65665641857174],
                    [35.67949847213761,139.65717585604295],
                    [35.67885669136177,139.65741673491732],
                    [35.67254931759156,139.66062078853315],
                    [35.67097706336946,139.66141431576193],
                    [35.67005733868385,139.66159256614958],
                    [35.66905541377828,139.66144322119635],
                    [35.66256444178188,139.66032695676222],
                    [35.65978636839026,139.66002255564263],
                    [35.65599090955914,139.6599428606595],
                    [35.64252765008547,139.66082427936783],
                    [35.63978053415771,139.6614342803589],
                    [35.636711376022745,139.66311910524223],
                    [35.6331948158973,139.66770384022175],
                    [35.63153398180192,139.6701844927676],
                    [35.62969752904402,139.67244393691465],
                    [35.62748064402348,139.6766033411052],
                    [35.627042069737286,139.6771380922695],
                    [35.62376715687506,139.67911780875522],
                    [35.620522832572576,139.6804589185292],
                    [35.61765626161484,139.68219643925],
                    [35.61582469830745,139.6833278111717],
                    [35.61328941224676,139.68667807160955],
                    [35.608607322143456,139.693913247929],
                    [35.60814122690469,139.6943853706125],
                    [35.605390801380025,139.6962641940645],
                    [35.60367517522808,139.6975408522802],
                    [35.602970112699936,139.69815750228713],
                    [35.60052939425688,139.7012792505525],
                    [35.600235607405395,139.70180918414587],
                    [35.597032325803085,139.7090554166707],
                    [35.59296533898873,139.71631853666804],
                    [35.59219496918335,139.7177044701291],
                    [35.591685684939165,139.718393383818],
                    [35.590929587755184,139.71874024943872],
                    [35.58311074765477,139.72035912431488],
                    [35.582448602390556,139.72059036806252],
                    [35.581900075535174,139.72101913251845],
                    [35.58137897154947,139.72172731651915],
                    [35.58063061327999,139.72292689348762],
                    [35.58039160733503,139.7237747872323],
                    [35.57885008436364,139.72967779922203],
                    [35.57837958614499,139.73213596337519],
                    [35.577623363305236,139.73511804430348],
                    [35.5775802622902,139.7361779114833],
                    [35.57784670444961,139.73716551500115],
                    [35.579327793232125,139.73978627755355],
                    [35.579540588836736,139.7403151694814],
                    [35.58246430854069,139.7487795564116],
                    [35.583790466586066,139.75276151791604],
                    [35.58449961596378,139.75541118592673],
            ], {color: "#0cad4daa", weight: 20})
            , "環七通り", "Kannana-dori Ave.", [35.64306427390535,139.8669846975506], "#0cad4d", "かんなな"],

            "route_Sangyo": [
                L.polyline([
                    [35.5740156172501,139.73397909461028],
                    [35.57003411407722,139.73500067347004],
                    [35.56612264528458,139.73628615428584],
                    [35.56310252470453,139.73719600818492],
                    [35.56079593330254,139.73780400639043],
                    [35.5593939829449,139.73827702364804],
                    [35.55153149603527,139.74063703967613],
                    [35.54715707557317,139.74118477338652],
                    [35.5451315375676,139.74126825734834],
            ], {color: "#5a828aaa", weight: 20})
            , "産業道路", "Sangyo-doro Ave.", [35.5740156172501,139.73397909461028], "#5a828a", "さんぎょう"],

            "route_GaienHigashi": [
                L.polyline([
                    [35.659691494604715,139.74293826426208],
                    [35.65953837306169,139.74265302975664],
                    [35.65949698879251,139.7421182151529],
                    [35.661200711913,139.7378774632209],
                    [35.6620579183177,139.73503561711823],
                    [35.66230621550528,139.73424103530397],
                    [35.66323732307667,139.7323106090123],
                    [35.664335946145826,139.73108727863973],
                    [35.66698551463227,139.727395382469],
                    [35.67065289310722,139.72660601800658],
                    [35.672144017414425,139.72605984701408],
                    [35.67170541256607,139.72448087035752],
                    [35.674354324771684,139.72322847656505],
                    [35.67476395162423,139.72238805351253],
                    [35.675243915759665,139.72197548220856],
                    [35.67716664771852,139.7209659530131],
                    [35.68041850813675,139.7192835937691],
                    [35.68416883216278,139.71996319109894],
                    [35.68794564708712,139.72035808412056],
                    [35.68902124705642,139.72047014052828],
                    [35.68950112542322,139.72088271185294],
                    [35.691665152089996,139.72337238389719],
                    [35.692893759722054,139.72408547014265],
                    [35.69551246310398,139.72491570632405],
                    [35.69939889245914,139.7262155515857],
                    [35.70080110506612,139.72659756207258],
                    [35.704003432053824,139.72673508583023],
                    [35.704739657625254,139.72698975950846],
                    [35.70605078410176,139.72709162897672],
                    [35.707452879720734,139.726954105206],
                    [35.709654304111055,139.72703732807554],
            ], {color: "#966a0baa", weight: 20})
            , "外苑東通り", "Gaienhigashi-dori Ave.", [35.659691494604715,139.74293826426208], "#966a0b", "がいえんひがし"],

            "route_GaienNishi": [
                L.polyline([
                    [35.63697113720323,139.7240256791133],
                    [35.63933446965224,139.72263018214267],
                    [35.641559258349645,139.72229141879745],
                    [35.64329472633429,139.72085386674098],
                    [35.64479389968963,139.72185334610572],
                    [35.64765242696488,139.7229060217586],
                    [35.650721164564956,139.72230362129335],
                    [35.65290747024538,139.72221441095303],
                    [35.653931617140245,139.72248841074816],
                    [35.65642148792743,139.72381692701686],
                    [35.65800580435075,139.72378205431153],
                    [35.65971092247622,139.72347160618324],
                    [35.66117729248825,139.7230142381361],
                    [35.66146062526363,139.72275020198504],
                    [35.66219728575393,139.7215296574468],
                    [35.662593946295345,139.72112114867696],
                    [35.66438963736583,139.72044366465582],
                    [35.66566862302918,139.7206130463386],
                    [35.66638096059144,139.7204785373508],
                    [35.66716747000686,139.71991756750526],
                    [35.667381977385745,139.71958876778285],
                    [35.66754791665936,139.7190507318404],
                    [35.6677421865907,139.7179896053931],
                    [35.667928361495754,139.7174914239497],
                    [35.668349277092545,139.71705302430462],
                    [35.6691020629023,139.71594207971077],
                    [35.670474057500826,139.71383477221184],
                    [35.67161939186086,139.7128035366204],
                    [35.67239238209386,139.71257437316592],
                    [35.67441183432935,139.71260924590888],
                    [35.67563400304701,139.7127039004106],
                    [35.676933042658035,139.71260426414415],
                    [35.67791236695169,139.71281848216313],
                    [35.679684868695176,139.71364225559353],
                    [35.68097574912103,139.71375683735937],
                    [35.68150990042885,139.71420520065453],
                    [35.68249726161433,139.7156200359857],
                    [35.68298689111893,139.7159039994126],
                    [35.68751064710386,139.7159663143491],
                    [35.688506026500164,139.71564249642336],
                    [35.68937191554615,139.7156873327774],
                    [35.69011236461513,139.71533362397213],
                    [35.69131810850608,139.71460129726154],
            ], {color: "#63960baa", weight: 20})
            , "外苑西通り", "Gaiennishi-dori Ave.", [35.63697113720323,139.7240256791133], "#63960b", "がいえんにし"],

            "route_Harumi": [
                L.polyline([
                    [35.67671379201812,139.75586523730783],
                    [35.675392439760486,139.7589827601289],
                    [35.67252679420845,139.76324090631294],
                    [35.67027025013721,139.76628063928547],
                    [35.66654649951863,139.77062618869581],
                    [35.66458261683721,139.77257640287587],
                    [35.663487481615235,139.7737645585279],
                    [35.66026408679842,139.77675396083282],
                    [35.659332007356525,139.777671708546],
                    [35.655299380908474,139.78176649802538],
                    [35.65859300101977,139.78666807301227],
                    [35.65900962230704,139.79208513673484],
                    [35.64964746736088,139.8013918787941],
                    [35.64092433717542,139.80258946069432],
            ], {color: "#cc9bf2aa", weight: 20})
            , "晴海通り", "Harumi-dori Ave.", [35.67671379201812,139.75586523730783], "#cc9bf2", "はるみ"],

            "route_Hibiya": [
                L.polyline([
                    [35.69005811007662,139.76446930980978],
                    [35.682210625460186,139.7622274311501],
                    [35.67548628626501,139.75991905798446],
                    [35.67309649023571,139.75759545272945],
                    [35.669532313494884,139.75542548166504],
                    [35.66097303666177,139.75142522048236],
                    [35.65938201778678,139.75066944269008],
                    [35.65263392644576,139.74962481100806],
                    [35.647564535665865,139.74878923688652],
            ], {color: "#b8abc2aa", weight: 20})
            , "日比谷通り", "Hibiya-dori Ave.", [35.69005811007662,139.76446930980978], "#b8abc2", "ひびや"],

            "route_Sakurada": [
                L.polyline([
                    [35.67769856225571,139.7534751785356],
                    [35.67468191012965,139.75152641134696],
                    [35.673031197790465,139.75046297058736],
                    [35.670409100408904,139.7493438855189],
                    [35.66886331323387,139.74844260325995],
                    [35.667933582119886,139.74801237960526],
                    [35.66442909432065,139.7464360204351],
                    [35.663180311981705,139.74525433563326],
                    [35.66164023446153,139.74387666653683],
                    [35.66066938015078,139.74320050290066],
                    [35.65970623828103,139.74293372452996],
                    [35.65927043371981,139.74303351612684],
                    [35.65698561332644,139.7442384100409],
                    [35.656023967271,139.74489883587682],
                    [35.65492055716217,139.745233404631],
                    [35.65188699049641,139.74504391138748],
                    [35.64791846880031,139.74456725282857],
                    [35.64789726841927,139.74101910182003],
                    [35.64566766251181,139.739397712877],
                    [35.64393972570119,139.73635667946576],
                    [35.64293440305627,139.7341594149251],
                    [35.639819383406646,139.73244861491457],
                    [35.63887571096906,139.73192162867883],
                    [35.635842282648554,139.73137228300584],
                    [35.632270150372776,139.7308361756326],
                    [35.631765602306274,139.73049676077687],
                    [35.63032383882856,139.7285552627627],
                    [35.62946354336043,139.72703682791337],
                    [35.628062398529806,139.72551577791887],
                    [35.627416252017596,139.72474762849083],
                    [35.62554525832317,139.72190010656618],
                    [35.62521683602583,139.72165375240843],
                    [35.623913792509725,139.72100298788848],
            ], {color: "#e0098eaa", weight: 20})
            , "桜田通り", "Sakurada-dori Ave.", [35.67769856225571,139.7534751785356], "#e0098e", "さくらだ"],

            "route_Aoyama": [
                L.polyline([
                    [35.67985153257135,139.74586567526944],
                    [35.678777553716344,139.7402517493813],
                    [35.67845527898774,139.73698538519182],
                    [35.677762531265174,139.73605905086833],
                    [35.674848517584465,139.7310343109532],
                    [35.674490184348755,139.72941200057494],
                    [35.67275124843751,139.72400304534233],
                    [35.67174390336055,139.7210426964133],
                    [35.67010983314145,139.7169327077945],
                    [35.6691104182423,139.71593285485642],
                    [35.66517285326904,139.71250567311563],
                    [35.66305614204548,139.71055082205157],
                    [35.660156704846386,139.70599312345348],
                    [35.658161595629394,139.70413555352093],
                    [35.658042126423325,139.70315040430364],
            ], {color: "#4a10b5aa", weight: 20})
            , "青山通り", "Aoyama-dori Ave.", [35.67985153257135,139.74586567526944], "#4a10b5", "あおやま"],

            "route_Roppongi": [
                L.polyline([
                    [35.676951740681034,139.74979270238236],
                    [35.674020646767914,139.74792811383634],
                    [35.67233369561518,139.7462021863546],
                    [35.671652201925724,139.74365111422264],
                    [35.669540826709394,139.7413462209322],
                    [35.66742866392532,139.73943814214908],
                    [35.665388232318705,139.73652836348742],
                    [35.663239385280775,139.73230820021604],
                    [35.66146281834244,139.7285744207379],
                    [35.6597420805724,139.7234378954804],
                    [35.65937334621934,139.71885146658042],
                    [35.65837647899234,139.70841086240569],
                    [35.658161595629394,139.70413555352093],
            ], {color: "#490d6eaa", weight: 20})
            , "六本木通り", "Roppongi-dori Ave.", [35.676951740681034,139.74979270238236], "#490d6e", "ろっぽんぎ"],

            "route_Mejiro": [
                L.polyline([
                    [35.69545804610921,139.75140180432675],
                    [35.697278495570686,139.7507693417117],
                    [35.698046166097086,139.75014304361548],
                    [35.70076103795307,139.7475415235706],
                    [35.702656287499096,139.74466372722395],
                    [35.70770140720808,139.74304907183728],
                    [35.70808361386801,139.74253169514498],
                    [35.709082461159596,139.73493758729796],
                    [35.71009188715486,139.7323400776404],
                    [35.71013926682643,139.7315348373221],
                    [35.71076467581727,139.73147259643474],
                    [35.712287968090976,139.73087107703088],
                    [35.712073185860135,139.72863395298236],
                    [35.712265858212646,139.72779370220337],
                    [35.7128217627091,139.72702736233214],
                    [35.71371603590404,139.72611658301628],
                    [35.71512751662743,139.72473401698585],
                    [35.71616817750551,139.71969907307687],
                    [35.71691039913742,139.7180224616459],
                    [35.71775849275143,139.7161148694015],
                    [35.71904600382332,139.7132609451403],
                    [35.71984504733926,139.71138594107265],
                    [35.72071210696555,139.70851018055555],
                    [35.72261601506314,139.70472708990087],
                    [35.7225939080265,139.7024047301669],
                    [35.72190661076512,139.69756504649501],
                    [35.72196345793819,139.69531659776305],
                    [35.72234243803733,139.69421960369561],
                    [35.72281372048687,139.68961037152462],
                    [35.7228841345013,139.68605274402557],
                    [35.72263148307344,139.68406492838062],
                    [35.72333574708418,139.68348142081254],
                    [35.726576411211795,139.68180257665256],
                    [35.72731221996861,139.68130854030403],
                    [35.72777012327283,139.68065501186118],
                    [35.72953157698103,139.677512428113],
                    [35.731780954946075,139.6726730155674],
                    [35.73404240319155,139.66637372936793],
                    [35.734853864927715,139.66258426133595],
                    [35.735409353536696,139.65377597767156],
                    [35.73577534447591,139.65265711520397],
                    [35.73694322802095,139.6498785625098],
                    [35.73739791839455,139.6474472813851],
                    [35.73740107596525,139.64598462266486],
                    [35.73753053596204,139.6451949425159],
                    [35.73796627774544,139.6441951996444],
                    [35.738436749886716,139.64288036275207],
                    [35.73954097816487,139.63960683382095],
                    [35.74027172066704,139.63772333872862],
                    [35.744744447494924,139.62880377821853],
                    [35.74854650320651,139.62157010888768],
                    [35.7488780002212,139.620834889403],
                    [35.74907374067257,139.62005687942838],
                    [35.74919055329035,139.61856310030532],
                    [35.74947499097331,139.6142067988569],
                    [35.749803327046564,139.61300088338976],
                    [35.75029619500191,139.61220252020746],
                    [35.75401518918693,139.60829475407354],
                    [35.754428742521455,139.60775792717823],
                    [35.75474443066287,139.6069371265894],
                    [35.75560577846518,139.60393112800057],
                    [35.75613296915405,139.6025307100481],
                    [35.756549668298405,139.60168656924304],
            ], {color: "#2b6e4baa", weight: 20})
            , "目白通り", "Mejiro-dori Ave.", [35.69545804610921,139.75140180432675], "#2b6e4b", "めじろ"],

            "route_ShinMejiro": [
                L.polyline([
                    [35.71015914785333,139.73159912128654],
                    [35.709877882899164,139.7295162038812],
                    [35.70965583092561,139.72702764169253],
                    [35.711667846576404,139.71949565953514],
                    [35.71232288152011,139.71727600792065],
                    [35.7129261010102,139.7144592836437],
                    [35.714622386131595,139.7106614422189],
                    [35.71624433469661,139.70416035246467],
                    [35.71629984314714,139.7026015827107],
                    [35.716822222761365,139.69463346139037],
                    [35.71735509848078,139.691875988633],
                    [35.71899222624616,139.68925016270543],
                    [35.722635750931985,139.68405989854364],
            ], {color: "#43b078aa", weight: 20})
            , "新目白通り", "Shinmejiro-dori Ave.", [35.71015914785333,139.73159912128654], "#43b078", "しんめじろ"],

            "route_Otakebashi": [
                L.polyline([
                    [35.72311758852699,139.77819243686895],
                    [35.72403165513262,139.7786588449702],
                    [35.72489024112545,139.77895592018206],
                    [35.733182107613175,139.77866864734779],
                    [35.733713817666654,139.77864333570028],
                    [35.734210580646206,139.77841458782018],
                    [35.73682007772612,139.77645761070687],
                    [35.73722760062749,139.776843808492],
                    [35.74965473487126,139.78512930664408],
                    [35.75206977132764,139.7870987558956],
                    [35.75233038198165,139.78726214730398],
                    [35.753635877807994,139.78786913693068],
                    [35.75398304303613,139.7881454168919],
                    [35.755732388741905,139.7899575242991],
                    [35.760719425616415,139.7887872021231],
                    [35.76391246092612,139.78696079586848],
                    [35.76936457403512,139.78560669677472],
                    [35.77189242094627,139.78471448010168],
                    [35.77904904005631,139.7848055654125],
                    [35.77993026788208,139.78500157742747],
                    [35.781503196937436,139.78559623562137],
                    [35.787908663418904,139.78609287570237],
                    [35.78924915182186,139.7863235297756],
                    [35.800363239616274,139.78982901092195],
                    [35.80140458456627,139.79029424030819],
                    [35.80324087019012,139.7912669952899],
                    [35.80425379720215,139.79121845463237],
                    [35.80517130918838,139.7910596886062],
            ], {color: "#08c4b8aa", weight: 20})
            , "尾竹橋通り", "Otakebashi-dori Ave.", [35.72311758852699,139.77819243686895], "#08c4b8", "おたけばし"],

            "route_Ogubashi": [
                L.polyline([
                    [35.724037976515774,139.77865182686935],
                    [35.72638309742611,139.77514359771658],
                    [35.7294998182885,139.77211420361414],
                    [35.73246068441338,139.76854135131657],
                    [35.73297414926971,139.76811838756385],
                    [35.73349070394718,139.76807266177047],
                    [35.73924959510356,139.76867852082444],
                    [35.74512110546,139.76930482818864],
                    [35.74696185320096,139.7696411732561],
                    [35.749177472130285,139.76969625312142],
                    [35.75271603877836,139.76992179925833],
                    [35.76013027386483,139.77087853305352],
                    [35.76489033456747,139.77081169649546],
                    [35.76704342726755,139.7707676813941],
                    [35.76891099142233,139.7706459760251],
                    [35.77312270892929,139.7703389010819],
                    [35.77820589529577,139.7701788699286],
                    [35.78490215338684,139.77000233206695],
                    [35.78886751745979,139.77004871822578],
                    [35.79587728942309,139.77018019307252],
                    [35.80174444405927,139.77017625081567],
                    [35.80697010675803,139.77010936132888],
                    [35.811014697937665,139.77007104538256],
                    [35.813773994495776,139.77065258617915],
                    [35.814864650783576,139.77076711869063],
                    [35.817057077316136,139.7709493553225],
            ], {color: "#7bedd8aa", weight: 20})
            , "尾久橋通り", "Ogubashi-dori Ave.", [35.724037976515774,139.77865182686935], "#7bedd8", "おぐばし"],

            "route_ChibaKaido": [
                L.polyline([
                    [35.703246493462174,139.8679931153788],
                    [35.707516547417924,139.8677617938148],
                    [35.707898162346964,139.86765850457027],
                    [35.70827138838514,139.86741061029056],
                    [35.70859009685775,139.86775146493937],
                    [35.712899203885996,139.87142262745192],
                    [35.716076030509655,139.87414509864016],
                    [35.71655823906575,139.87462539382136],
                    [35.71915387157496,139.87738828352462],
                    [35.72063140618097,139.87895367312808],
                    [35.72251922374451,139.88095257029818],
                    [35.723988197140514,139.88251209958912],
                    [35.72878010514761,139.8875452638856],
                    [35.7312751220191,139.8901777411395],
                    [35.734191894399686,139.8932848886693],
                    [35.73510997589676,139.89427130136096],
                    [35.73570106386647,139.89487037922402],
                    [35.73527346874269,139.89641455404058],
                    [35.734176842467065,139.89957191068527],
            ], {color: "#203b21aa", weight: 20})
            , "千葉街道", "Chiba-kaido Ave.", [35.703246493462174,139.8679931153788], "#203b21", "ちば"],

            "route_Keiyo": [
                L.polyline([
                    [35.694928842306055,139.784481540348],
                    [35.69447956196554,139.78769927772066],
                    [35.694153935203005,139.78980044999332],
                    [35.694203397568614,139.79102359627268],
                    [35.69511043913697,139.8078759728487],
                    [35.69524645896172,139.8093021308714],
                    [35.69554322868183,139.81414039237666],
                    [35.69568336953036,139.81508439735535],
                    [35.6957699270002,139.81676939541967],
                    [35.69623568703642,139.81925629023112],
                    [35.696507748186704,139.8234742995603],
                    [35.696581939542625,139.82576325792797],
                    [35.69669322643142,139.8282146256413],
                    [35.69687870427421,139.83014831322407],
                    [35.69748295454164,139.84352372246212],
                    [35.69767667391375,139.84442712510918],
                    [35.70028308585775,139.85312756691042],
                    [35.700472676878874,139.85411724956384],
                    [35.701645818948165,139.86121030695506],
                    [35.702428896046754,139.8644280443275],
                    [35.7032449366464,139.8679756758561],
                    [35.702966907775235,139.87561936615782],
                    [35.70251603881805,139.88146592610906],
                    [35.70200424896632,139.8901620742247],
                    [35.70159332751474,139.89727671007722],
                    [35.70160157047394,139.89794664907004],
                    [35.70402760596758,139.90530511615748],
                    [35.70506205951911,139.90833506754325],
                    [35.70603468087593,139.9104007128292],
                    [35.7089825635573,139.91554068996538],
            ], {color: "#ad1c43aa", weight: 20})
            , "京葉道路", "Keiyo-doro Ave.", [35.694928842306055,139.784481540348], "#ad1c43", "けいよう"],

            "route_Yasukuni": [
                L.polyline([
                    [35.694928842306055,139.784481540348],
                    [35.69515842338201,139.78272887063395],
                    [35.69536963736657,139.7800263669269],
                    [35.695577791865404,139.77531108470885],
                    [35.69583732630019,139.76894673449897],
                    [35.69576845260741,139.768392664658],
                    [35.69456544955375,139.76531135813957],
                    [35.6943312750697,139.76406187420912],
                    [35.69441851660392,139.76291415814444],
                    [35.69463891585967,139.76246185627997],
                    [35.69600721431624,139.76062437991254],
                    [35.69587568730397,139.75533026121457],
                    [35.695793038915326,139.75397900937128],
                    [35.69567824935672,139.75299525278479],
                    [35.6951134823757,139.74928072357307],
                    [35.693963395258336,139.74524829203267],
                    [35.69338484096758,139.74381223356852],
                    [35.69114323042206,139.73624381482853],
                    [35.69117996516822,139.73588197331958],
                    [35.6921350630181,139.73516394407454],
                    [35.69188710600923,139.73457029784458],
                    [35.6910576767084,139.7305319012171],
                    [35.69135155463043,139.72756932391894],
                    [35.69211838725087,139.72600322870971],
                    [35.692398485927114,139.7218470148215],
                    [35.691213338061445,139.71694478867562],
                    [35.69142456250499,139.71548611513822],
                    [35.69118578697963,139.7137108302827],
                    [35.69180734530376,139.70819801509015],
                    [35.693107245848566,139.70336389346141],
                    [35.693378157641135,139.7022896764911],
                    [35.69370416890652,139.7005935444888],
                    [35.69363070168146,139.69894264263075],
            ], {color: "#d9333faa", weight: 20})
            , "靖国通り", "Yasukuni-dori Ave.", [35.694928842306055,139.784481540348], "#d9333f", "やすくに"],

            "route_Shinjuku": [
                L.polyline([
                    [35.68393063984891,139.74411167597782],
                    [35.68420083448278,139.74081586930492],
                    [35.68395131367055,139.73814533022858],
                    [35.68390558836177,139.7370245512676],
                    [35.68419241038812,139.73445545974312],
                    [35.684803462573356,139.73329373907586],
                    [35.68561403476896,139.7310521811371],
                    [35.685867596684005,139.72946569035253],
                    [35.687452769467086,139.72497492410196],
                    [35.68787259138598,139.72322466649518],
                    [35.688092893090825,139.72186335502488],
                    [35.6875180007917,139.71597813326093],
                    [35.68887449928083,139.70926238084667],
                    [35.69084716055879,139.70485005136402],
                    [35.69225238739959,139.70168555752525],
                    [35.69246436419871,139.70076948703525],
                    [35.69268880961705,139.70051871914893],
                    [35.69342448737109,139.7002884221406],
                    [35.69363070168146,139.69894264263075],
            ], {color: "#00ff1eaa", weight: 20})
            , "新宿通り", "Shinjuku-dori Ave.", [35.68393063984891,139.74411167597782], "#00ff1e", "しんじゅく"],

            "route_Hakusan": [
                L.polyline([
                    [35.69035396256611,139.75879080266145],
                    [35.69156386240253,139.75896076870106],
                    [35.69193332479374,139.75895077069845],
                    [35.69644771482943,139.75795744327172],
                    [35.70093415082833,139.75551335083946],
                    [35.701717641783404,139.7552384058081],
                    [35.70226973442138,139.7554533628463],
                    [35.70844826949141,139.7536188379729],
                    [35.71118084077182,139.75279572187628],
                    [35.713088670040094,139.75238657384216],
                    [35.719024476674406,139.75133629242168],
                    [35.71968603180518,139.75100635839738],
                    [35.72358361944761,139.74698075391643],
                    [35.724992566498784,139.74608608373296],
                    [35.72699347099805,139.74573954972539],
                    [35.7274561080153,139.74552959169375],
                    [35.72866599051373,139.74387593361962],
                    [35.731614210735955,139.74082029710848],
                    [35.73458913621424,139.73724510586464],
                    [35.74420609043956,139.7280656922785],
            ], {color: "#4e3578aa", weight: 20})
            , "白山通り", "Hakusan-dori Ave.", [35.69035396256611,139.75879080266145], "#4e3578", "はくさん"],

            "route_KyuHakusan": [
                L.polyline([
                    [35.71899065936475,139.7513314001767],
                    [35.722607345051046,139.75264886898026],
                    [35.72369231874988,139.75205174284918],
                    [35.7244310157991,139.75063001398007],
                    [35.72526204179319,139.7492651542426],
                    [35.72596994598452,139.74794768545542],
                    [35.72658550972973,139.7470188225932],
                    [35.72767042925335,139.74517057507273],
            ], {color: "#a83260aa", weight: 20})
            , "旧白山通り", "Kyu-hakusan-dori Ave.", [35.71899065936475,139.7513314001767], "#a83260", "きゅうはくさん"],

            "route_Hongo": [
                L.polyline([
                    [35.69007738540421,139.7644445119688],
                    [35.694369018781444,139.76532947295232],
                    [35.69609270481861,139.7650221278626],
                    [35.69803298440449,139.76600215277392],
                    [35.69925269910647,139.76550344189332],
                    [35.70019455097717,139.76556143151856],
                    [35.70146603335124,139.76610073518384],
                    [35.704222433371896,139.7618229247355],
                    [35.70482518226879,139.7612140335125],
                    [35.705653954565804,139.76083710085868],
                    [35.70952714537319,139.76028075252225],
                    [35.71601281130363,139.75860267896599],
                    [35.717918975931966,139.75795816555456],
                    [35.71863932490582,139.7575986297984],
                    [35.71940674834143,139.7570013365572],
                    [35.723061725857676,139.75412251311127],
                    [35.72453057107151,139.7537455804636],
                    [35.72656745049015,139.75335704986537],
                    [35.727198276231505,139.75270756590453],
                    [35.730442116133204,139.7509875946197],
                    [35.73227201462335,139.74933595366747],
                    [35.73524064170077,139.74709788505515],
                    [35.73634210970789,139.7466513648808],
                    [35.73820453506333,139.74612907123094],
                    [35.74151131881159,139.7474161565483],
                    [35.74304101091414,139.7471204093988],
                    [35.744302396428836,139.7450269834344],
                    [35.74454714054724,139.74437170051488],
                    [35.74489072236828,139.74333948493688],
                    [35.747102018030745,139.7412195211914],
                    [35.74878797872314,139.73908131186624],
                    [35.749865727847265,139.73811288491083],
                    [35.75083051269864,139.73742280823114],
            ], {color: "#8b72b3aa", weight: 20})
            , "本郷通り", "Hongo-dori Ave.", [35.69007738540421,139.7644445119688], "#8b72b3", "ほんごう"],

            "route_Kasuga": [
                L.polyline([
                    [35.73347989326638,139.71671364537997],
                    [35.732117052883,139.7194585447246],
                    [35.72915432521276,139.72490399754713],
                    [35.72559158938161,139.73020570830704],
                    [35.72150914430432,139.73178704197312],
                    [35.720216114161616,139.73301652255236],
                    [35.7187932569248,139.73526368019907],
                    [35.71799683142606,139.73641278722812],
                    [35.71724135760334,139.73764036983493],
                    [35.71619915607249,139.73907535227238],
                    [35.71537539761218,139.73984329209952],
                    [35.71438323523316,139.74047670231528],
                    [35.71292227640353,139.74264599219566],
                    [35.710928313894996,139.74541267314385],
                    [35.709685764037616,139.74808084363565],
                    [35.70859794808108,139.74972322589588],
                    [35.70842498851863,139.75380395693946],
                    [35.70733260370582,139.75624230597145],
                    [35.70751466885356,139.76060891258123],
                    [35.70751011721043,139.76373112048464],
                    [35.70731439711503,139.76583314551885],
                    [35.70761025286609,139.76698785795884],
                    [35.70827023480609,139.76829391621152],
                    [35.70825658005337,139.76988584993887],
                    [35.70798803622672,139.77306661034459],
                    [35.7076603205514,139.77627826754292],
                    [35.706064037159464,139.7907437589024],
                    [35.70519907992204,139.7933043512212],
                    [35.70365325506742,139.79692118016578],
                    [35.703779920934494,139.8022408851598],
                    [35.70439049387289,139.81440783934318],
                    [35.70465024148619,139.8191634008923],
            ], {color: "#f765ccaa", weight: 20})
            , "春日通り", "Kasuga-dori Ave.", [35.73347989326638,139.71671364537997], "#f765cc", "かすが"],

            "route_Shinobazu": [
                L.polyline([
                    [35.70977602229418,139.77348461390886],
                    [35.70986257091322,139.77267986587253],
                    [35.709317312967485,139.76979130002098],
                    [35.70984526116149,139.7689599047137],
                    [35.710390515463985,139.76876804427616],
                    [35.71153726830906,139.7686667846084],
                    [35.71255418627146,139.7684962420018],
                    [35.71457038314203,139.76782116412937],
                    [35.71667196369965,139.7664045675433],
                    [35.71999465306392,139.76312529612517],
                    [35.72076916060264,139.76238450157973],
                    [35.72121049672077,139.76211802870785],
                    [35.72189413020946,139.76221395894686],
                    [35.72710270800168,139.76372734574034],
                    [35.72843958306386,139.7634928496591],
                    [35.7299062260107,139.76243228769624],
                    [35.73349438459813,139.7563823999301],
                    [35.73364147339342,139.7556522643249],
                    [35.73329751812935,139.75191627072286],
                    [35.73312447166977,139.75073313125165],
                    [35.72968768081458,139.74534015064626],
                    [35.728666655084965,139.7439225150065],
                    [35.72564331538929,139.74057567430793],
                    [35.72352834529336,139.73720745623245],
                    [35.722857705975954,139.73651995626346],
                    [35.72201399042497,139.73497441364566],
                    [35.721611599855535,139.73359941365607],
                    [35.7214991031925,139.7317873982007],
                    [35.72082844679484,139.72922392926216],
                    [35.72004095915962,139.72701753392744],
                    [35.71958663583932,139.72508294096565],
                    [35.71866067361762,139.72306307670033],
                    [35.716844327865175,139.71815909852876],
            ], {color: "#9c824faa", weight: 20})
            , "不忍通り", "Shinobazu-dori Ave.", [35.70977602229418,139.77348461390886], "#9c824f", "しのばず"],

            "route_Kototoi": [
                L.polyline([
                    [35.71509954947207,139.8017828874215],
                    [35.718140103943654,139.7893147661614],
                    [35.720680338705776,139.78137247654843],
                    [35.72101737147595,139.7808784707122],
                    [35.72251519338731,139.77947607482895],
                    [35.7228117754637,139.77901943075688],
                    [35.723610519460095,139.77676942131257],
                    [35.72362063006544,139.77631277717262],
                    [35.723576817313145,139.77583952790155],
                    [35.72346223000447,139.77549496923007],
                    [35.722351947956,139.77394431208631],
                    [35.72202503164897,139.77354993767847],
                    [35.7213880465262,139.77183544698858],
                    [35.72110157007256,139.77085158674902],
                    [35.720649946280986,139.769398628599],
                    [35.717643265400866,139.7647547565623],
                    [35.71627315196402,139.76273340645366],
                    [35.715693420177104,139.76162500691908],
                    [35.715336141495314,139.75878551156325],
            ], {color: "#5a8f73aa", weight: 20})
            , "言問通り", "Kototoi-dori Ave.", [35.71509954947207,139.8017828874215], "#5a8f73", "こととい"],

            "route_ShinOhashi": [
                L.polyline([
                    [35.66380005023433,139.7631397578548],
                    [35.663370486790654,139.76404614054547],
                    [35.66297160437057,139.76539492433983],
                    [35.66300228770139,139.76639302434876],
                    [35.66417701209025,139.76786589627136],
                    [35.66555334632308,139.76939271953847],
                    [35.66937063751695,139.774178946216],
                    [35.6703743342337,139.77511769976468],
                    [35.674600184556,139.77684534783987],
                    [35.68245939163211,139.7817134301108],
                    [35.687953096007476,139.79315589517182],
                    [35.68803197127224,139.7982770819989],
                    [35.68865033416286,139.81021258546457],
                    [35.68901322113395,139.81570734098955],
                    [35.689357010907,139.82636876990853],
                    [35.68930442825267,139.82976230998372],
                    [35.690664053145085,139.84442146363403],
                    [35.69096150027396,139.85004217822325],
                    [35.690803755419054,139.85137477660066],
                    [35.68795035132894,139.8595360559243],
                    [35.68634902395813,139.865062288564],
                    [35.684668423127434,139.86978438910853],
                    [35.68297972815539,139.8780238952113],
                    [35.683217251314645,139.88755363654855],
                    [35.68293678858156,139.8884330435733],
                    [35.682169893270455,139.8894527240842],
                    [35.68181931010628,139.8897764322012],
                    [35.68107431576506,139.8903321311327],
            ], {color: "#d6a51eaa", weight: 20})
            , "新大橋通り", "Shin-ohashi-dori Ave.", [35.66380005023433,139.7631397578548], "#d6a51e", "しんおおはし"],

            "route_Kaigan": [
                L.polyline([
                    [35.666493352717964,139.7626305487629],
                    [35.66536375568052,139.76316796993297],
                    [35.66421010815853,139.76327644028902],
                    [35.66379350913266,139.76310880434391],
                    [35.65953990986286,139.75965904694453],
                    [35.65900711223502,139.75949141099477],
                    [35.658153827423945,139.75994994466043],
                    [35.6576971360396,139.76014223296897],
                    [35.65272452760945,139.76017092668803],
                    [35.636059742993,139.75174127721425],
                    [35.63388173061672,139.7506866331846],
                    [35.62418149739436,139.7498595675786],
                    [35.62344005894785,139.75005678634625],
                    [35.62192887208256,139.75095461213868],
                    [35.62053614966251,139.74628595634613],
                    [35.61921752670207,139.74686775174348],
                    [35.61696744724257,139.74769430345123],
                    [35.615440591509696,139.74780491837305],
                    [35.60699996507262,139.74706152289627],
                    [35.60288040685387,139.74565159297413],
                    [35.60047310556995,139.7453002791981],
                    [35.59936153777767,139.7434583372335],
                    [35.59865995962204,139.74281244574627],
                    [35.596064051780886,139.74212273542003],
                    [35.59453155027373,139.74133092856027],
                    [35.59135792382422,139.73912844804],
                    [35.5904558034334,139.7386255401816],
                    [35.589641881644425,139.73869949723462],
                    [35.58904045629481,139.73909393482154],
                    [35.58678059514996,139.74171905494245],
                    [35.58569798757918,139.74282841057288],
                    [35.584683530878436,139.74344471926383],
                    [35.57664027952315,139.74757847604462],
            ], {color: "#0862bdaa", weight: 20})
            , "海岸通り", "Kaigan-dori Ave.", [35.666493352717964,139.7626305487629], "#0862bd", "かいがん"],

            "route_KyuKaigan": [
                L.polyline([
                    [35.649089587910254,139.75306825607345],
                    [35.64857412118212,139.7547223555232],
                    [35.64831964902723,139.75499536227585],
                    [35.642900044953535,139.7523728841336],
                    [35.64289351953789,139.75078302161],
                    [35.64179724815999,139.74825369476844],
                    [35.64114469851603,139.74790039199524],
                    [35.63737703869229,139.74672916956445],
                    [35.63540621354689,139.74610286007544],
                    [35.633024191165205,139.7453801952895],
                    [35.62703155701779,139.74478137897268],
                    [35.62239465802854,139.7453623173392],
                    [35.62049526282301,139.7462616335672],
            ], {color: "#bd501eaa", weight: 20})
            , "旧海岸通り", "Kyu-kaigan-dori Ave.", [35.649089587910254,139.75306825607345], "#bd501e", "きゅうかいがん"],

            "route_Maruhachi": [
                L.polyline([
                    [35.7117638110613,139.82871713134148],
                    [35.70747821459864,139.83194497027637],
                    [35.70694404072076,139.8322575616014],
                    [35.70449127343083,139.83312379297308],
                    [35.70164117978736,139.83422687598147],
                    [35.70095920512719,139.8343528456212],
                    [35.70045529787697,139.83426420035426],
                    [35.69948536218237,139.83390028805525],
                    [35.69907616701431,139.83383030493565],
                    [35.69486437525465,139.83423077710415],
                    [35.69404972449409,139.83440340219943],
                    [35.68986164344433,139.83577733602533],
                    [35.68841791028342,139.83623455922566],
                    [35.6858262117338,139.83644923349425],
                    [35.6848788352751,139.83633259496276],
                    [35.67625495679658,139.8337860469294],
                    [35.6716388725814,139.83244047949097],
                    [35.667601135655545,139.83074671827478],
            ], {color: "#bd0820aa", weight: 20})
            , "丸八通り", "Maruhachi-dori Ave.", [35.7117638110613,139.82871713134148], "#bd0820", "まるはち"],

            "route_Edo": [
                L.polyline([
                    [35.71511092784151,139.80178975096305],
                    [35.71374991672437,139.80059479829947],
                    [35.71239786616637,139.79910110742935],
                    [35.71070440907384,139.79769691533033],
                    [35.709752100267934,139.79707177804494],
                    [35.708813256372935,139.79615343476894],
                    [35.70642342205545,139.7944937783036],
                    [35.705197031892496,139.79332095439315],
                    [35.70197785260916,139.78943122451312],
                    [35.70157352769395,139.78913801853594],
                    [35.69634390236233,139.78566848648757],
                    [35.695503743365975,139.78518718611815],
                    [35.692066617882816,139.78076698770224],
                    [35.68997154775845,139.77712503723467],
                    [35.6875618103567,139.76998876854373],
                    [35.68658226498788,139.76921979440135],
                    [35.684176125516615,139.76836312671594],
            ], {color: "#6ea3c4aa", weight: 20})
            , "江戸通り", "Edo-dori Ave.", [35.71511092784151,139.80178975096305], "#6ea3c4", "えど"],

            "route_Kiyosumi": [
                L.polyline([
                    [35.7084907179501,139.80224441317273],
                    [35.707721939754975,139.7985674041172],
                    [35.70367294882054,139.79691839212512],
                    [35.692168037661546,139.79788173232987],
                    [35.683280863647525,139.79862097645128],
                    [35.68202917537434,139.79877107331163],
                    [35.67951551299508,139.79905992152817],
                    [35.676108746437095,139.79631235983356],
                    [35.67417602586961,139.79595788540155],
                    [35.671941101717636,139.79450532306168],
                    [35.659306999587784,139.77765368445876],
                    [35.65653271849138,139.7735863743469],
                    [35.6550444337977,139.77138603571836],
            ], {color: "#24a39baa", weight: 20})
            , "清澄通り", "Kiyosumi-dori Ave.", [35.7084907179501,139.80224441317273], "#24a39b", "きよすみ"],

            "route_Mitsume": [
                L.polyline([
                    [35.71287160546758,139.80571569624124],
                    [35.71229864366778,139.80519109615352],
                    [35.710135818282794,139.80417163994412],
                    [35.70938566408692,139.80419020988245],
                    [35.70855257000727,139.8043619816168],
                    [35.703881270782375,139.80475886115937],
                    [35.697332595517594,139.8052992320138],
                    [35.694984936422145,139.8054949379839],
                    [35.69337028176369,139.8056204396578],
                    [35.682489151551536,139.80654898244939],
                    [35.676225757532634,139.80703468827733],
                    [35.66943567385542,139.80583865647463],
                    [35.668096948054014,139.805293690815],
                    [35.665000929355855,139.8052457383385],
                    [35.6628331499458,139.80554285702442],
                    [35.659593370699824,139.80734113825315],
                    [35.65632513052766,139.81059355754064],
                    [35.655864927568956,139.81095567088335],
                    [35.655280239650935,139.81110423021664],
                    [35.644268530777055,139.81271990693028],
            ], {color: "#879493aa", weight: 20})
            , "三ツ目通り", "Mitsume-dori Ave.", [35.71287160546758,139.80571569624124], "#879493", "みつめ"],

            "route_Yotsume": [
                L.polyline([
                    [35.71739086279938,139.82318366402865],
                    [35.713873626468754,139.81803488299977],
                    [35.7120565061002,139.81607222937942],
                    [35.71136668269871,139.8153387177382],
                    [35.709748603443984,139.81409028464645],
                    [35.709514886683436,139.81375138370777],
                    [35.70905876006596,139.81367710406244],
                    [35.706630753457915,139.8142143447849],
                    [35.697915742682085,139.81497726536793],
                    [35.69567216784391,139.8150877680648],
                    [35.69306874223585,139.81530344586713],
                    [35.692273182126684,139.81542879280642],
                    [35.68422375616616,139.81612706202895],
                    [35.67655266948867,139.8159187923278],
                    [35.669559926652724,139.81643835780233],
            ], {color: "#a4bfbdaa", weight: 20})
            , "四ツ目通り", "Yotsume-dori Ave.", [35.71739086279938,139.82318366402865], "#a4bfbd", "よつめ"],

            "route_Kuramaebashi": [
                L.polyline([
                    [35.70320457541072,139.76336146209155],
                    [35.703221257756226,139.76485600087472],
                    [35.70281670962305,139.7666073332598],
                    [35.70295433961496,139.76958613905776],
                    [35.70295433960159,139.7717432053372],
                    [35.70168806608575,139.7860701258278],
                    [35.70177147942337,139.78807311593732],
                    [35.70157128725839,139.7891208338856],
                    [35.70137943596784,139.79227939513203],
                    [35.700307562718734,139.79510412479488],
                    [35.700228318594384,139.797148201867],
                    [35.700852417857334,139.80901305609007],
                    [35.70091914922294,139.8094650128187],
                    [35.70173129428021,139.82536224656496],
                    [35.70161281525476,139.83306387955793],
                    [35.70167120465746,139.83367504833768],
                    [35.70190476181561,139.83438893455696],
                    [35.702617941048075,139.8354212448271],
                    [35.70760791260197,139.84102542848268],
                    [35.709172066816436,139.8427235525894],
                    [35.71002696905134,139.84399211301024],
                    [35.71149667344389,139.84618657989336],
                    [35.71555274158071,139.85178897496291],
                    [35.723810782911116,139.8635027552806],
                    [35.72835697578884,139.87007073941686],
                    [35.73447370566829,139.87899769309718],
                    [35.73739282148827,139.88893580394165],
                    [35.737376146286294,139.88970618471967],
                    [35.73571185460986,139.89489041412003],
            ], {color: "#c29d53aa", weight: 20})
            , "蔵前橋通り", "Kuramaebashi-dori Ave.", [35.70320457541072,139.76336146209155], "#c29d53", "くらまえばし"],

            "route_Kiyosubashi": [
                L.polyline([
                    [35.71985619001342,139.78387274875058],
                    [35.71902499698777,139.78375144767392],
                    [35.71659538590893,139.782911252757],
                    [35.71140689959272,139.78306255051325],
                    [35.708559091888105,139.78215290924732],
                    [35.705190941627876,139.7816257863659],
                    [35.70225026886659,139.78096004666236],
                    [35.69711175326725,139.78006277347913],
                    [35.694556313324725,139.78001727043807],
                    [35.69034562417748,139.7847362600653],
                    [35.688648056028896,139.78582646888344],
                    [35.68564913938299,139.7882169538775],
                    [35.6838231127617,139.7897622322339],
                    [35.68301913172789,139.7907326408813],
                    [35.6817102537439,139.79325041662264],
                    [35.68316931659399,139.8185908733667],
                    [35.68190120811387,139.82108798744196],
                    [35.681030207317406,139.82233981458876],
                    [35.678165264775004,139.82473838709984],
                    [35.677510999133304,139.82565542325756],
                    [35.67706956386202,139.82713529640387],
                    [35.675418348681944,139.84053400489415],
                    [35.675323753127685,139.84108228578452],
                    [35.67469311327294,139.84201387808832],
                    [35.67456304319401,139.84245541406926],
                    [35.67453545252374,139.84284842960528],
            ], {color: "#4385baaa", weight: 20})
            , "清洲橋通り", "Kiyosubashi-dori Ave.", [35.71985619001342,139.78387274875058], "#4385ba", "きよすばし"],

            "route_Eitai": [
                L.polyline([
                    [35.68568969502951,139.76122089359114],
                    [35.683594246984654,139.77095110366963],
                    [35.67992925413323,139.78011897709814],
                    [35.67904734504313,139.78184640333598],
                    [35.676598063714934,139.786115725733],
                    [35.676050043454694,139.78875683865056],
                    [35.67371628250011,139.7919152943308],
                    [35.669961529857446,139.80047331059848],
                    [35.66954562209146,139.80289911539944],
                    [35.66931156591619,139.8085117766218],
                    [35.66993504328599,139.8257656397327],
                    [35.6698728515845,139.82738284293987],
                    [35.6694569433525,139.82824886005324],
                    [35.669103225297675,139.82861249156633],
                    [35.66845409028693,139.8290574616974],
                    [35.66796043319006,139.82966510905953],
                    [35.66760282140871,139.83073686505554],
                    [35.66596519069175,139.8362166329939],
                    [35.66592631895246,139.83695824985352],
                    [35.666039046934785,139.83794388259457],
            ], {color: "#a227baaa", weight: 20})
            , "永代通り", "Eitai-dori Ave.", [35.68568969502951,139.76122089359114], "#a227ba", "えいたい"],

            "route_DainiKeihin": [
                L.polyline([
                    [35.62388759671323,139.72099742940122],
                    [35.61527225873125,139.71668169361067],
                    [35.60937181445637,139.71448804643933],
                    [35.600729446851275,139.7127086589985],
                    [35.59556349261999,139.71168050668246],
                    [35.59439442851587,139.71141508609196],
                    [35.59340520710482,139.71093585450978],
                    [35.5927097471545,139.71042713172847],
                    [35.58732490810391,139.70630857533547],
                    [35.581712905260844,139.7020169539544],
                    [35.570607875933234,139.69638564478305],
                    [35.56894668272737,139.6961202242262],
                    [35.560047969274706,139.69717003291976],
                    [35.55922026645476,139.69711105056194],
                    [35.55808665955932,139.69680876607435],
                    [35.55626325826515,139.69618207861845],
            ], {color: "#ff670faa", weight: 20})
            , "第二京浜", "Dainikeihin Ave.", [35.62388759671323,139.72099742940122], "#ff670f", "だいにけいひん"],

            "route_NakaharaKaido": [
                L.polyline([
                    [35.620997824943444,139.7196533623761],
                    [35.61801841472008,139.7144325636375],
                    [35.61696835683854,139.71191320231856],
                    [35.616135243265035,139.70966072257897],
                    [35.61575773581417,139.70914831011032],
                    [35.6154713496622,139.7089134544117],
                    [35.61361646001194,139.7075639913876],
                    [35.61231786924165,139.70674723642787],
                    [35.61124170001972,139.7059679424642],
                    [35.60922535796422,139.70501250673556],
                    [35.6079712237769,139.70451076954646],
                    [35.60740273432444,139.70418517413623],
                    [35.60705556320039,139.7036834369469],
                    [35.60674310790505,139.702359704758],
                    [35.60650876563611,139.70172986445232],
                    [35.60607479665798,139.7009345576505],
                    [35.60496382535476,139.69918381504897],
                    [35.60367056590012,139.69756117556653],
                    [35.603010908719526,139.69645094859334],
                    [35.60176343789968,139.69458378864454],
                    [35.600648066747596,139.69299851255914],
                    [35.6003659665116,139.6923579970421],
                    [35.60015330567511,139.69145060001983],
                    [35.600118585461885,139.6899934270814],
                    [35.600066505144326,139.68923548366433],
                    [35.59921585524991,139.68620904756233],
                    [35.59821329198162,139.68434621477047],
                    [35.597957224021314,139.6839619054192],
                    [35.596633469839574,139.6826915495119],
                    [35.59432010257582,139.68135714206483],
                    [35.592822671637265,139.68096749508152],
                    [35.59180700687132,139.68060453626777],
                    [35.59139900114896,139.68034299242868],
                    [35.58933082841193,139.67838430254506],
                    [35.589018303897205,139.6778505395406],
                    [35.58672888091702,139.67273789439287],
                    [35.58637293858612,139.6712753838034],
                    [35.585869407698695,139.669924963472],
                    [35.58540060021973,139.66900155351686],
            ], {color: "#deb40baa", weight: 20})
            , "中原街道", "Nakahara-kaido Ave.", [35.620997824943444,139.7196533623761], "#deb40b", "なかはら"],

            "route_Nakasendo": [
                L.polyline([
                    [35.74419413820422,139.72807814888233],
                    [35.74540559152008,139.72685052035604],
                    [35.748134460930956,139.7225524604504],
                    [35.74863921039403,139.72151952193326],
                    [35.74877966183159,139.72058392845133],
                    [35.74940836391775,139.71430601735983],
                    [35.749763877741614,139.712240140336],
                    [35.749996497040286,139.71174259925078],
                    [35.75161441667417,139.70985128489735],
                    [35.752285922856174,139.70921313438362],
                    [35.75342264263337,139.70857498387772],
                    [35.76132788906334,139.70557177160538],
                    [35.76676926021062,139.70343720969458],
                    [35.7676381016182,139.70307486999513],
                    [35.76972444754604,139.70154781131077],
                    [35.77154767860458,139.69994691228794],
                    [35.77444609928037,139.696975550042],
                    [35.77527032081798,139.6959541631818],
                    [35.776297022614685,139.69481306355078],
                    [35.784667414158264,139.68651982689346],
                    [35.785110512472315,139.68615748720651],
                    [35.787037574317395,139.68524720576033],
                    [35.788358048386854,139.68477129692337],
                    [35.79206246351137,139.68538782670726],
                    [35.79363289235553,139.68555006838199],
                    [35.79550596103266,139.68558251674688],
                    [35.80016152094831,139.68441924365493],
            ], {color: "#8a710caa", weight: 20})
            , "中仙道", "Nakasendo Ave.", [35.74419413820422,139.72807814888233], "#8a710c", "なかせんどう"],

            "route_Meguro": [
                L.polyline([
                    [35.640358482788926,139.7327916967629],
                    [35.640153405500484,139.7307183895],
                    [35.63993169972977,139.72995453946646],
                    [35.63855910781976,139.72749891659691],
                    [35.637589121172425,139.72548699012611],
                    [35.63697940923943,139.72401385073545],
                    [35.63646946479095,139.7228407953205],
                    [35.636031575115,139.72190644302486],
                    [35.63479549385012,139.719628533044],
                    [35.6337922030647,139.71707781948203],
                    [35.63384209071944,139.71464304742335],
                    [35.63427999236269,139.7136336741662],
                    [35.63447399864013,139.71226283612953],
                    [35.63415804526561,139.71145806556183],
                    [35.63363699663719,139.71062601461495],
                    [35.633221263906336,139.70949387972792],
                    [35.63211817589445,139.70735237159033],
                    [35.63181329963952,139.70645893982964],
                    [35.631619286878404,139.70522450360386],
                    [35.63050509018446,139.70120065068272],
                    [35.62978445719513,139.6995638291778],
                    [35.62842078006186,139.69453742302358],
                    [35.624116171282125,139.6866894608579],
                    [35.62124371353421,139.68153032685495],
                    [35.62051745306641,139.6804663928558],
                    [35.61980781814921,139.6792114963332],
                    [35.618278554437865,139.6754381590781],
                    [35.61787383021655,139.67461974830212],
                    [35.61538022622118,139.67073929110302],
                    [35.61409947010799,139.66750656856175],
                    [35.613910958816234,139.6664903751962],
                    [35.613800069619955,139.66456710986563],
                    [35.61392204770877,139.6612730064819],
                    [35.6137606150301,139.658848973246],
                    [35.61256300127788,139.6548796810306],
                    [35.612307951578416,139.65421131226037],
                    [35.609907401286165,139.65065154226727],
                    [35.606974674250694,139.64781421836824],
                    [35.60613183939489,139.64740501299684],
                    [35.60502837771273,139.64745957375305],
                    [35.604362966323194,139.64754823494673],
                    [35.602112843021416,139.6474254733513],
                    [35.601186776928344,139.64720723048606],
                    [35.600266245525134,139.6469480670546],
                    [35.5997449760458,139.64668890367852],
                    [35.59878006403564,139.6460205348983],
                    [35.5983256213501,139.64578202931682],
                    [35.598464020523025,139.64550799413132],
                    [35.59851522816511,139.64528757451535],
            ], {color: "#672fa3aa", weight: 20})
            , "目黒通り", "Meguro-dori Ave.", [35.640358482788926,139.7327916967629], "#672fa3", "めぐろ"],

            "route_Bokutei": [
                L.polyline([
                    [35.70849193918431,139.80223713079283],
                    [35.71092736688077,139.80206355645464],
                    [35.71179025925362,139.80238589728106],
                    [35.71226873108955,139.80283918908515],
                    [35.713622340994334,139.80430483259352],
                    [35.71631968150329,139.80707966052807],
                    [35.7174115125255,139.8080315733257],
                    [35.71835202911939,139.8087366939178],
                    [35.71832340485517,139.80947203396727],
                    [35.72317504750268,139.8125110267672],
                    [35.723776118087386,139.8126973800497],
                    [35.724696115300844,139.812722562918],
                    [35.72504366703262,139.81270241660013],
                    [35.72551388166082,139.81274270923745],
                    [35.727538728777745,139.81313235413973],
                    [35.73010331663434,139.81387902233928],
                    [35.730990532535046,139.81441793592148],
                    [35.7335951351733,139.8165900493217],
                    [35.734535460717325,139.81770313254924],
                    [35.73503423758321,139.8179348150254],
                    [35.73638746073874,139.81804058313628],
                    [35.736959814056334,139.81794488818673],
                    [35.73999983514385,139.81649806060705],
                    [35.74194312664601,139.81438989111598],
                    [35.742695309104334,139.81323147869034],
                    [35.74378677861671,139.81139816517953],
                    [35.74417921343738,139.81070815432827],
                    [35.74418330129035,139.8102548625095],
                    [35.74333223652316,139.80626560635596],
                    [35.743221863238475,139.8052028888917],
                    [35.74330770911709,139.80286591780452],
                    [35.74352436737128,139.80125925016205],
                    [35.74392906706818,139.7990179740127],
                    [35.74437873100034,139.7977940861473],
                    [35.74526987564762,139.79596077261294],
                    [35.74593209594816,139.79488798200214],
                    [35.746340871154146,139.79464118981116],
                    [35.75072755399813,139.79437961643328],
                    [35.75245099257825,139.7930471423217],
                    [35.75491532635807,139.79093017643595],
                    [35.75514830218488,139.79069345736366],
                    [35.75574913143957,139.78994804418426],
            ], {color: "#313131aa", weight: 20})
            , "墨堤通り", "Bokutei-dori Ave.", [35.70849193918431,139.80223713079283], "#313131", "ぼくてい"],

            "route_HeiwaBashi": [
                L.polyline([
                    [35.76161667682979,139.8035304671962],
                    [35.761230387088844,139.80522713796722],
                    [35.76089362015047,139.80642945501336],
                    [35.76042808702583,139.80769890654437],
                    [35.759546538149976,139.8096885276717],
                    [35.75884327304969,139.81084812283098],
                    [35.757931990703085,139.81230677147892],
                    [35.7567730622314,139.81386307019943],
                    [35.75545477855823,139.8153218710388],
                    [35.754588033153354,139.8163105784888],
                    [35.75415713334259,139.81701854183814],
                    [35.75406302847121,139.81753730806827],
                    [35.75412246311281,139.82101609349434],
                    [35.751912417284956,139.8210884481498],
                    [35.75201147770069,139.82233348717318],
                    [35.75196690050704,139.8231329975539],
                    [35.75169943691624,139.82362124815882],
                    [35.750545370766574,139.8255193222917],
                    [35.749212973171296,139.8276615217294],
                    [35.74617395490118,139.83044643583548],
                    [35.73849051278363,139.8377367669325],
                    [35.7359422828397,139.8411907574888],
                    [35.72911066704151,139.84720154893375],
                    [35.72718154517729,139.84904445573784],
                    [35.71924534508864,139.8569298768581],
                    [35.71679818963226,139.85948667047137],
                    [35.71221288706987,139.86446361885623],
                    [35.708293810773725,139.86739209951168],
            ], {color: "#3029ffaa", weight: 20})
            , "平和橋通り", "Heiwabashi-dori Ave.", [35.76161667682979,139.8035304671962], "#3029ff", "へいわばし"],

            "route_NikkoKaido": [
                L.polyline([
                    [35.73002585629719,139.79175811990626],
                    [35.731092094149616,139.7921799640701],
                    [35.73246183860111,139.79275041243204],
                    [35.734346639946125,139.7942157947291],
                    [35.7368330740607,139.79669412918804],
                    [35.73849843565323,139.79713514809396],
                    [35.74069681526137,139.79809388485614],
                    [35.74144386139784,139.79809388489798],
                    [35.74247492920661,139.79776312071237],
                    [35.74338147968747,139.7975761670715],
                    [35.744377506074684,139.79780147022348],
                    [35.75475453390054,139.8013163376363],
                    [35.762422456109604,139.8037987826674],
                    [35.77868524747004,139.80312798866703],
                    [35.78274458696575,139.80300156647968],
                    [35.78736053324947,139.8028590781073],
                    [35.78933980693682,139.80276320442226],
                    [35.80036898760234,139.8018549180622],
                    [35.80462072644717,139.8015505124897],
                    [35.80669549254581,139.8014273308395],
                    [35.80836327930639,139.80166701506437],
            ], {color: "#9bff29aa", weight: 20})
            , "日光街道", "Nikko-kaido Ave.", [35.73002585629719,139.79175811990626], "#9bff29", "にっこう"],

            "route_Takashima": [
                L.polyline([
                    [35.784659247448104,139.6865228462609],
                    [35.785793960397385,139.6741086310883],
                    [35.78822183874988,139.66295808147584],
                    [35.7888646206021,139.6600298028161],
                    [35.79134592539235,139.64745379990723],
                    [35.792016131413845,139.64375170666813],
                    [35.79251294331496,139.63976908505492],
            ], {color: "#27597aaa", weight: 20})
            , "高島通り", "Takashima-dori Ave.", [35.784659247448104,139.6865228462609], "#27597a", "たかしま"],

            "route_Sasame": [
                L.polyline([
                    [35.793792315228615,139.6444395653847],
                    [35.79244621081021,139.64034668570957],
                    [35.7929001739262,139.63611733209927],
                    [35.792947617319015,139.63549733727996],
                    [35.79284798611462,139.63410527344524],
                    [35.79240201635815,139.63246170228763],
                    [35.79192283330582,139.63154925707818],
                    [35.791410436342986,139.63083567814124],
                    [35.78860947852401,139.62718380912725],
                    [35.78833903593289,139.62686796272908],
                    [35.78794997655447,139.62645853219078],
                    [35.7876652977548,139.62617193080098],
                    [35.784229083505096,139.62298787835414],
                    [35.78157605363943,139.62053597685258],
                    [35.78086429765848,139.61992768007576],
                    [35.780128809796224,139.6196059846593],
                    [35.77948821804496,139.6195416455787],
                    [35.77500806104234,139.6197960663809],
                    [35.769707326244784,139.62012111515946],
                    [35.769023938631264,139.62009771913506],
                    [35.76667949484707,139.61972338271357],
                    [35.765673356081166,139.6195771575417],
                    [35.76488552157127,139.61963564760947],
                    [35.759028432374365,139.62051405347984],
                    [35.75812187167527,139.62064858068368],
                    [35.75746211681735,139.62069537275022],
                    [35.75653180571652,139.62064858071756],
                    [35.75575812032908,139.6204789595065],
                    [35.75402560738506,139.61998764288592],
                    [35.74920262787164,139.61860262089726],
                    [35.745770301244406,139.61702352423492],
                    [35.74526156952811,139.61681739820017],
                    [35.74153169660492,139.61538894503985],
                    [35.74092877355285,139.61526026688364],
                    [35.736071916144525,139.61477034587745],
                    [35.73355736938856,139.6146546269788],
            ], {color: "#83c215aa", weight: 20})
            , "笹目通り", "Sasame-dori Ave.", [35.793792315228615,139.6444395653847], "#83c215", "ささめ"],

            "route_ShinOmiyaByPass": [
                L.polyline([
                    [35.767110555901766,139.65298979944896],
                    [35.76824454136772,139.6525136337258],
                    [35.77017170995548,139.65186420987402],
                    [35.77194285980541,139.65123962882376],
                    [35.773131966590725,139.65097990209642],
                    [35.776621359898016,139.65060201753545],
                    [35.77839114919344,139.65041820595502],
                    [35.77920890928472,139.65025123874352],
                    [35.77982096960723,139.65005953567015],
                    [35.78036780640991,139.64981217683612],
                    [35.78111029439174,139.6493360111042],
                    [35.78169725633457,139.64889694917724],
                    [35.78230929749472,139.6483465757974],
                    [35.78530412732401,139.64546419744386],
                    [35.78596630554537,139.64492619199632],
                    [35.78706490723633,139.644091355952],
                    [35.78766185795597,139.64375123754223],
                    [35.78858988167612,139.6433430954787],
                    [35.789668382141244,139.64318231225002],
                    [35.79065657669251,139.64330599170978],
                    [35.791323726462224,139.64346677496678],
                    [35.79202096723422,139.64374505367675],
                    [35.79378159837272,139.6444314744756],
                    [35.79796845513681,139.64616693664084],
            ], {color: "#c2157daa", weight: 20})
            , "新大宮バイパス", "Shin-omiya By-Pass", [35.767110555901766,139.65298979944896], "#c2157d", "しんおおみや"],

            "route_KawagoeKaido": [
                L.polyline([
                    [35.733547042266636,139.7168039377168],
                    [35.73483818492721,139.71565837896537],
                    [35.73537336746669,139.71519685889393],
                    [35.736831721609065,139.7143067844952],
                    [35.737172892349605,139.713944161595],
                    [35.740063353524306,139.70837939153046],
                    [35.74121391602051,139.7067640712853],
                    [35.75008327813987,139.6954478052893],
                    [35.75515926456002,139.68808074974072],
                    [35.75957523960234,139.68010837830275],
                    [35.761903913829926,139.67189651331523],
                    [35.76393703171813,139.6646722088235],
                    [35.765000555076554,139.6607881251972],
                    [35.766037075075104,139.65703003320846],
                    [35.7671070170251,139.6530082154395],
                    [35.768779937606695,139.64634483245868],
                    [35.76910759756795,139.64549596515576],
                    [35.770672322394944,139.64279277620153],
                    [35.770939793647386,139.64221587612053],
                    [35.77164190139386,139.63905940849327],
                    [35.771936116133205,139.638268231246],
                    [35.77235737625671,139.63746881255793],
                    [35.772685021475354,139.63694136104027],
                    [35.773895291090724,139.63546614516008],
                    [35.774751161234605,139.63476562364463],
                    [35.77518577927697,139.63411455067603],
                    [35.77835376520737,139.62811654100278],
                    [35.77933662267573,139.62569356065092],
                    [35.77970195090471,139.62492776800218],
            ], {color: "#6e0845aa", weight: 20})
            , "川越街道", "Kawagoe-kaido Ave.", [35.733547042266636,139.7168039377168], "#6e0845", "かわごえ"],

            "route_Waseda": [
                L.polyline([
                    [35.695013983887534,139.74882724924169],
                    [35.69548300634989,139.74869017262586],
                    [35.69575097167369,139.74820039644692],
                    [35.69654648844569,139.7472620883584],
                    [35.69733362355164,139.7468857340309],
                    [35.69903736369551,139.745304219005],
                    [35.6998370344228,139.74403080089417],
                    [35.70042736210924,139.742705827402],
                    [35.701946884778636,139.73868858985588],
                    [35.70265860797345,139.73741001621204],
                    [35.703583838700524,139.73596130976568],
                    [35.70450471616343,139.73089869236742],
                    [35.704827075361834,139.7304553160177],
                    [35.70471822693324,139.7269547051094],
                    [35.70540480677205,139.72507293342062],
                    [35.705925913464114,139.7200583307664],
                    [35.70642409550666,139.71901691191601],
                    [35.707637917016214,139.71777876227645],
                    [35.709353716550524,139.71649364025166],
                    [35.70946674485819,139.71612244147173],
                    [35.70989792548545,139.71356013859474],
                    [35.71109935825332,139.71138965671594],
                    [35.71147611042079,139.71004921665394],
                    [35.71191146625921,139.707672513219],
                    [35.713360276567414,139.70501558393016],
                    [35.71351515938596,139.704685629418],
                    [35.7138039941275,139.70390198752511],
                    [35.713699343965146,139.70275230235666],
                    [35.713381206641,139.70166448367104],
                    [35.712468647832836,139.70006626658574],
                    [35.71186166484303,139.6979679622434],
                    [35.711694220442965,139.69714823152992],
                    [35.711514217326055,139.69670485520874],
                    [35.7113007247239,139.69630787869437],
                    [35.710714663685266,139.6956067254082],
                    [35.71012441213912,139.6949055721342],
                    [35.70963462562719,139.69385384218685],
                    [35.710714663645575,139.69237935806768],
                    [35.711124906802226,139.69128638382085],
                    [35.71128816625672,139.6904047866692],
                    [35.71116258206651,139.68815697169703],
                    [35.71100769464478,139.68747128499717],
                    [35.710325349205576,139.684594494277],
                    [35.709923474318956,139.6823621459498],
                    [35.708464007135845,139.67921193419033],
                    [35.708367722532756,139.67879433553594],
                    [35.70832585960295,139.67668056457433],
                    [35.70916311340514,139.6730046652989],
                    [35.70977195446203,139.66961131651806],
                    [35.70997880825449,139.66667207338332],
                    [35.70976178869353,139.6560641200483],
                    [35.70967806433663,139.65419265938908],
                    [35.709426890719314,139.65090858110898],
                    [35.71000040259712,139.6485988996278],
                    [35.71055297947345,139.64684601640693],
                    [35.712752826481946,139.6392813391751],
                    [35.71413716159064,139.63480826254477],
                    [35.714863363141774,139.6319494082229],
                    [35.715307956516895,139.63059626116],
                    [35.71595258623866,139.6289155554873],
                    [35.71658883903115,139.6270183171555],
                    [35.719260303911355,139.61794226089523],
                    [35.71908941710598,139.61408556484264],
                    [35.71909920787479,139.60597295180355],
                    [35.71703296970665,139.596568659338],
            ], {color: "#868f24aa", weight: 20})
            , "早稲田通り", "Waseda-dori Ave.", [35.695013983887534,139.74882724924169], "#868f24", "わせだ"],

            "route_KyuWaseda": [
                L.polyline([
                    [35.71660259429409,139.62703778624655],
                    [35.718893736045146,139.62653486543468],
                    [35.720450059187215,139.62574768509535],
                    [35.721669059131216,139.62552173519612],
                    [35.72230813902651,139.62549986906333],
                    [35.722438321337336,139.62490948377808],
                    [35.72291762708724,139.62450860489594],
                    [35.72372238090422,139.6240858598983],
                    [35.72427268581109,139.62367769229922],
                    [35.72588748429976,139.62157927233284],
                    [35.72758724476388,139.61916082600374],
                    [35.728634545204045,139.61752086684112],
                    [35.73007773475942,139.61541222894266],
                    [35.73078774826871,139.61452300664132],
                    [35.73170483967414,139.6131017086591],
                    [35.73258642081399,139.61184805094717],
                    [35.73332599476811,139.61068185781664],
                    [35.73418389196324,139.61004045157006],
                    [35.734716375199156,139.60849524562423],
                    [35.734958949707476,139.60739465082793],
                    [35.73558017377431,139.60641796401342],
                    [35.736367050660036,139.60625032373355],
                    [35.737254496462754,139.6052153272501],
                    [35.737000096323484,139.60432610490034],
                    [35.73654454056208,139.6011846720629],
                    [35.73600615313259,139.59989457084066],
                    [35.735663003186346,139.5985534487046],
                    [35.7352843532701,139.59772253608037],
                    [35.735473678435824,139.5966000751523],
                    [35.735290269652154,139.59393969679047],
                    [35.736047567580016,139.59281723587196],
                    [35.736290138043,139.5919717458205],
                    [35.73667469947239,139.59111896707464],
                    [35.736982347261986,139.5906597785497],
                    [35.73731365893913,139.59009854808664],
                    [35.737372821585694,139.5888521791005],
                    [35.73759763927063,139.58778802784354],
                    [35.737621304240406,139.58700813615405],
                    [35.73788161858833,139.58641046216633],
                    [35.7379407808216,139.58606789296414],
                    [35.73797036192077,139.58562328180972],
                    [35.737934864585895,139.58474134822654],
                    [35.73820701031924,139.5846393063398],
            ], {color: "#0c39c2aa", weight: 20})
            , "旧早稲田通り", "Kyu-waseda-dori Ave.", [35.71660259429409,139.62703778624655], "#0c39c2", "きゅうわせだ"],

            "route_MitoKaido": [
                L.polyline([
                    [35.71511586969103,139.80179362221247],
                    [35.713560498747455,139.80438158888433],
                    [35.712875952607845,139.80575294442997],
                    [35.71803112640846,139.81338878818792],
                    [35.728615957661134,139.82504618022244],
                    [35.730496367953066,139.82711389837414],
                    [35.73452215988179,139.83251324998366],
                    [35.73649725919526,139.83511188874058],
                    [35.73749778424285,139.83658462858887],
                    [35.7384809719438,139.83773720757495],
                    [35.74150690720144,139.84095767347435],
                    [35.74431609037558,139.84367567752656],
                    [35.74611794349089,139.8453124294051],
                    [35.74953389637314,139.84787033509156],
                    [35.75117950555894,139.84910829030457],
                    [35.7522144896961,139.84999406859902],
                    [35.757361285170475,139.85454052535454],
                    [35.75759511396802,139.85482867010012],
                    [35.76186577008494,139.86224534975383],
                    [35.764967111035446,139.86761550073447],
                    [35.76694743291884,139.87109377051115],
                    [35.76825064614698,139.8737404333698],
                    [35.76844980640993,139.87448747528674],
                    [35.76851042030343,139.875319893467],
                    [35.768471454227736,139.8766112087909],
                    [35.768276623573385,139.87975976958614],
            ], {color: "#9c6e14aa", weight: 20})
            , "水戸街道", "Mito-kaido Ave.", [35.71511586969103,139.80179362221247], "#9c6e14", "みと"],

            "route_ShibamataKaido": [
                L.polyline([
                    [35.76695404097548,139.87110828876916],
                    [35.76001803271065,139.87527001106037],
                    [35.75756628965584,139.8763516771885],
                    [35.75413375467336,139.87748048131084],
                    [35.7499863455741,139.8792724343491],
                    [35.747063793587,139.88016860297193],
                    [35.74130162670984,139.88259002658924],
                    [35.736175550562336,139.88476792821302],
                    [35.73519605789305,139.88513077129255],
                    [35.7287757124801,139.88755659975368],
                    [35.716027103308875,139.89221904149008],
                    [35.71524726428234,139.89251252226177],
                    [35.71033461592425,139.89459820979937],
                    [35.701599863515,139.8979244398697],
                    [35.69452154469326,139.90152662454307],
                    [35.69389317214387,139.90199085775248],
                    [35.69325179367179,139.9027058836399],
                    [35.69117018216626,139.90540942591247],
                    [35.68816552729568,139.90925339023147],
            ], {color: "#6c1ac9aa", weight: 20})
            , "柴又街道", "Shibamata-kaido Ave.", [35.76695404097548,139.87110828876916], "#6c1ac9", "しばまた"],

            "route_OkudoKaido": [
                L.polyline([
                    [35.735928217728244,139.8412077229109],
                    [35.73629510646515,139.84335858035172],
                    [35.73634579380372,139.84368079066212],
                    [35.736628338953,139.84643430383312],
                    [35.736847627068684,139.8479045759724],
                    [35.737180775146726,139.84960344162175],
                    [35.73761934770177,139.85193093959901],
                    [35.73757296032359,139.85239332202082],
                    [35.73690666600015,139.8537908598472],
                    [35.73618554371451,139.85525074137422],
                    [35.73609698438905,139.85550531146941],
                    [35.73448769280911,139.86491203758817],
                    [35.73430213585951,139.8663927003306],
                    [35.73410814403266,139.8684864093924],
                    [35.73432322185795,139.8731466004292],
                    [35.7344786023214,139.87901995363768],
            ], {color: "#fa52a3aa", weight: 20})
            , "奥戸街道", "Okudo-kaido Ave.", [35.735928217728244,139.8412077229109], "#fa52a3", "おくど"],

            "route_Nakano": [
                L.polyline([
                    [35.67544487151231,139.67087420158236],
                    [35.67757048341862,139.67041709620912],
                    [35.68115488741648,139.66955767745776],
                    [35.68351221260515,139.66936392680054],
                    [35.686172057362526,139.66909457866063],
                    [35.688859007507716,139.66882997693983],
                    [35.69047978167066,139.66918939292123],
                    [35.69094160913444,139.66911965549764],
                    [35.6915123544708,139.66904991808474],
                    [35.69312871798762,139.66868513770635],
                    [35.694287599958415,139.66836863702156],
                    [35.695829845355036,139.667258202531],
                    [35.69740691290559,139.66741913507894],
                    [35.698735633459776,139.6675639743878],
                    [35.699101572275325,139.66758543207766],
                    [35.699498004100164,139.66753715230846],
                    [35.70111856206387,139.66698461731085],
                    [35.7014278583504,139.6668451424423],
                    [35.701680522027715,139.66666811667073],
                    [35.70232960265048,139.66599220005253],
                    [35.702752155662765,139.66564351289617],
                    [35.70309193892547,139.66551476688448],
                    [35.705328986396836,139.6654383460907],
                    [35.706143566255605,139.665368608702],
                    [35.70995858241302,139.6649591382146],
                    [35.710973479990145,139.66535610512676],
                    [35.71426902187504,139.66621894367586],
                    [35.71632146931374,139.6684953920966],
                    [35.718888300988866,139.67020540568558],
                    [35.72009471639733,139.67067211004837],
                    [35.72063476646002,139.67123000953984],
                    [35.7213141790982,139.6717932734698],
                    [35.72190648282023,139.6722009692785],
                    [35.723330606851974,139.67362254008412],
                    [35.72348738958638,139.67369227752033],
                    [35.72350916493323,139.6740087781891],
                    [35.7236398169256,139.67540889129373],
                    [35.72415806779112,139.67536061153646],
                    [35.725865223284,139.67577903616382],
                    [35.7269104024689,139.67644422401168],
                    [35.72954440326575,139.67751543132567],
            ], {color: "#9e2fa8aa", weight: 20})
            , "中野通り", "Nakano-dori Ave.", [35.67544487151231,139.67087420158236], "#9e2fa8", "なかの"],

            "route_FunaboriKaido": [
                L.polyline([
                    [35.703250229763725,139.86798902625878],
                    [35.69905772235549,139.86782232373196],
                    [35.696700910874725,139.86782232376993],
                    [35.691445733189894,139.86760992580548],
                    [35.69099819997989,139.86757931292425],
                    [35.69068989786621,139.86744461624414],
                    [35.68767217633395,139.86574435694638],
                    [35.6856303606539,139.86464546814864],
                    [35.6844567382342,139.8640332104926],
                    [35.68362624074901,139.8635678946721],
                    [35.679999677495815,139.8616323216636],
                    [35.67117580318029,139.85711697240004],
                    [35.67020589837176,139.85666390169683],
                    [35.66687062881519,139.85493168972937],
                    [35.66467603333922,139.85377218101803],
                    [35.66185976540376,139.85174295039405],
                    [35.66159114620743,139.85159600855195],
                    [35.661327500556936,139.8514858021831],
                    [35.66095441561191,139.85143069900755],
                    [35.657168869844014,139.85118241296573],
                    [35.65370465644659,139.85101499777284],
                    [35.64684409331258,139.85045559062155],
            ], {color: "#c95a34aa", weight: 20})
            , "船堀街道", "Funabori-kaido Ave.", [35.703250229763725,139.86798902625878], "#c95a34", "ふなぼり"],

            "route_OmeKaido": [
                L.polyline([
                    [35.69362221057395,139.69913198624295],
                    [35.693562375146385,139.6980636920349],
                    [35.693151005297565,139.6970322355437],
                    [35.69425377899445,139.69316204110055],
                    [35.69494187788845,139.69122806016503],
                    [35.696407807903284,139.6864759927863],
                    [35.696527474481385,139.68505774014045],
                    [35.69699865983931,139.68290273283174],
                    [35.69767332436547,139.6763111986154],
                    [35.69740329384119,139.66740714291223],
                    [35.69744068919488,139.66201962469154],
                    [35.698024054463275,139.65769119114384],
                    [35.69796361725443,139.65121947547868],
                    [35.697799078644934,139.64808826818376],
                    [35.69772428826664,139.64255339890587],
                    [35.69789630606829,139.64023262181513],
                    [35.69885361570975,139.63705536747477],
                    [35.70145730206306,139.6307103615468],
                    [35.7020481165717,139.62945787867784],
                    [35.70281093389336,139.62826065241347],
                    [35.703469044932916,139.62705421666624],
                    [35.705054471927596,139.62341649065024],
                    [35.70518908221604,139.62266131714955],
                    [35.70527134394368,139.6206812890373],
                    [35.7054956936736,139.619815602377],
                    [35.70636317331656,139.61795529694655],
                    [35.70809062620465,139.61507274443784],
                    [35.70876364967387,139.61385709931108],
                    [35.70998255548501,139.6113797618268],
                    [35.71071538559103,139.60954708469262],
                    [35.71108927592505,139.60776045467537],
                    [35.711403342443575,139.605384420983],
                    [35.712009038667674,139.60244661189597],
                    [35.712562386741354,139.60078891394642],
                    [35.71335501376623,139.5996653631264],
                    [35.7148654698359,139.5984036708121],
                    [35.71634598809483,139.59728012000937],
                    [35.71702641907875,139.59658020311747],
                    [35.71722830408912,139.59600921827382],
                    [35.71753486922884,139.59473831653202],
                    [35.71787134181179,139.593964724197],
                    [35.71959854528144,139.59213204705793],
                    [35.72002473259337,139.59140450185532],
                    [35.721505155004934,139.58852194936117],
                    [35.722858446372726,139.5842948196481],
                    [35.72322480307331,139.58165171237601],
                    [35.72308247374845,139.5764003189504],
                    [35.722962847073745,139.5741808456179],
                    [35.72308247381602,139.57088386856742],
                    [35.7230376138362,139.5656669826301],
                    [35.72311607689075,139.56488636877657],
                    [35.723285073974566,139.56364482107074],
                    [35.723635138251495,139.56235123240072],
                    [35.72397312987235,139.56134758605288],
                    [35.72470946381766,139.55770472147879],
                    [35.72531904659422,139.55435179920528],
                    [35.725723420730844,139.5526641864468],
                    [35.72609158047133,139.5514523763984],
                    [35.72639334947459,139.5508130165406],
                    [35.729312223254524,139.54575253422195],
                    [35.72925149770059,139.54443929120578],
                    [35.72875219676837,139.5435083847476],
                    [35.72953488338294,139.53995099218992],
                    [35.729892487660806,139.5383468408852],
            ], {color: "#ff8c00aa", weight: 20})
            , "青梅街道", "Ome-kaido Ave.", [35.69362221057395,139.69913198624295], "#ff8c00", "おうめ"],

            "route_KosyuKaido": [
                L.polyline([
                    [35.68751984715975,139.71592634302294],
                    [35.68719838986347,139.71418178693352],
                    [35.68721107898267,139.71351521034808],
                    [35.68762381632985,139.71152922801082],
                    [35.687974615190896,139.7098693963651],
                    [35.688280543180795,139.70877204924386],
                    [35.68873535394421,139.70749641458906],
                    [35.689334222325456,139.70632938234337],
                    [35.68955040786692,139.7059602518209],
                    [35.689764553349704,139.7053324788567],
                    [35.689809421862876,139.70464695081137],
                    [35.68978494814954,139.70413719922692],
                    [35.68939336760053,139.70194752720022],
                    [35.6892036950519,139.7012167994684],
                    [35.688956916123345,139.69992107608596],
                    [35.68686347214208,139.69477998133345],
                    [35.6835861108877,139.68995309292563],
                    [35.680171597480665,139.68466595504015],
                    [35.67826692513461,139.6803394222842],
                    [35.675452993582965,139.67089486198745],
                    [35.67328780290342,139.66343899946946],
                    [35.67293751751076,139.66218258293156],
                    [35.67193496806314,139.65825721044712],
                    [35.67164507189324,139.65717178546913],
                    [35.67085993115535,139.6550678452994],
                    [35.670624387395605,139.6528895609449],
                    [35.67014725823083,139.65019086747975],
                    [35.669887553809076,139.64946972898656],
                    [35.668891005779315,139.6477598130009],
                    [35.66812395728981,139.6455740942738],
                    [35.667985042208656,139.6448232181158],
                    [35.66756829548798,139.64164128743482],
                    [35.66742937944781,139.63918049529548],
                    [35.667550176017826,139.6380578982962],
                    [35.66919299122068,139.63307683862308],
                    [35.66941042008227,139.63213266760863],
                    [35.670333937304974,139.62558767442079],
                    [35.67061175901411,139.62340195563843],
                    [35.67097413370554,139.62094859788715],
                    [35.671227794998515,139.618859526611],
                    [35.67196798402345,139.61344964652676],
                    [35.672826333741014,139.6090142968418],
                    [35.67307998915621,139.60771327380235],
                    [35.67322493475524,139.60653120139068],
                    [35.67317058017146,139.6055349894075],
                    [35.672527588486574,139.60149203422588],
                    [35.67226789180661,139.60005719164474],
                    [35.67176661445279,139.59865952115524],
                    [35.67102375184717,139.5974402767431],
                    [35.66530149934805,139.58824712339458],
                    [35.664073521216494,139.5862765645204],
                    [35.662213198268674,139.58133458079124],
                    [35.66183870325197,139.5803904097968],
                    [35.66139172301855,139.57963209925788],
                    [35.660860175718426,139.57871023149744],
                    [35.660461512926815,139.57792218324334],
                    [35.65977666489131,139.57569132632386],
                    [35.65939007756563,139.57437543441938],
                    [35.65903112615294,139.57326107613096],
                    [35.6577721047571,139.57004987810012],
                    [35.65584642377134,139.5669488054673],
                    [35.65357132265159,139.56273703328975],
                    [35.65336236576914,139.56236118467086],
                    [35.652708702240005,139.56103582365154],
                    [35.652408658168895,139.56003356055396],
                    [35.65262610013768,139.55678542352064],
                    [35.65298508027874,139.55362697618193],
                    [35.65320475388506,139.55163563777182],
                    [35.65458870573305,139.54662959344057],
                    [35.656615636417385,139.53977502942394],
                    [35.657151400344645,139.5383046040588],
                    [35.659948179470064,139.53226862546194],
                    [35.660966085393994,139.53013881654374],
            ], {color: "#d1006caa", weight: 20})
            , "甲州街道", "Kosyu-kaido Ave.", [35.68751984715975,139.71592634302294], "#d1006c", "こうしゅう"],

            "route_ItsukaichiKaido": [
                L.polyline([
                    [35.697926480208125,139.64947854796537],
                    [35.697549186985626,139.64945774557987],
                    [35.69737461787291,139.64938147021235],
                    [35.697183154536816,139.64915264432466],
                    [35.69566242418237,139.64510021921626],
                    [35.69448481749659,139.64151046715335],
                    [35.69431024168415,139.64107361772392],
                    [35.69252254757622,139.6384398272569],
                    [35.69242681012338,139.6382387377696],
                    [35.692212808387964,139.63724715897092],
                    [35.69203259594293,139.6353818813932],
                    [35.69184111978548,139.6342169496852],
                    [35.6918580147469,139.63355127440286],
                    [35.69181296147872,139.63298267677806],
                    [35.69150885124098,139.63224766031692],
                    [35.69112589594191,139.63132542271183],
                    [35.6908105196152,139.63016742511718],
                    [35.69071478012067,139.62971670748902],
                    [35.69070351663693,139.62894702045378],
                    [35.690635935738456,139.6277612863828],
                    [35.690765465724766,139.62619417588053],
                    [35.69075983398491,139.62536901592284],
                    [35.69055709126508,139.62286579961847],
                    [35.68983189754246,139.6205078277261],
                    [35.68979247492217,139.62011258300026],
                    [35.69015407922275,139.61640104213802],
                    [35.690396245273874,139.614618973986],
                    [35.690638410589585,139.61380074812223],
                    [35.69094815585903,139.61294091751995],
                    [35.692377607887536,139.60949078183558],
                    [35.69322797805013,139.607826593661],
                    [35.6946439440505,139.6045799141507],
                    [35.697049369684606,139.59994503232406],
                    [35.69944003612282,139.5958163172047],
                    [35.700915030796395,139.5928078686826],
                    [35.70142745173598,139.5917954875249],
                    [35.70350606704582,139.58763601549322],
                    [35.705386361717785,139.58353390956097],
                    [35.70641677344996,139.58121791434758],
                    [35.7073289301249,139.57943584620193],
                    [35.70797081188873,139.57773005334604],
                    [35.70876471114509,139.57569835699292],
                    [35.70891673348261,139.57524070510226],
                    [35.70971936262583,139.5724586771505],
                    [35.71030846041866,139.5705817709818],
                    [35.711040403079565,139.56834205103007],
                    [35.712003459272864,139.56566531132353],
                    [35.7127860580014,139.5637861655017],
                    [35.71392912997976,139.5576884666557],
                    [35.714966591486316,139.55369993994123],
                    [35.71593079072363,139.55085966270212],
                    [35.716517204906765,139.5494434384167],
            ], {color: "#cfeb34aa", weight: 20})
            , "五日市街道", "Itsukaichi-kaido Ave.", [35.697926480208125,139.64947854796537], "#cfeb34", "いつかいち"],

            "route_Tamagawa": [
                L.polyline([
                    [35.658048548326164,139.70316563251652],
                    [35.65619412231847,139.6972982490156],
                    [35.65558974184515,139.69478262260895],
                    [35.65457467453297,139.69396829611867],
                    [35.65396349086201,139.69327461841317],
                    [35.65209402720416,139.68748911038256],
                    [35.65152357153383,139.68618533056986],
                    [35.64511993192866,139.67416584335353],
                    [35.64427096397749,139.67282027572207],
                    [35.643496697333845,139.67116547824855],
                    [35.64251187366797,139.67015421314795],
                    [35.63636138986479,139.66557386308227],
                    [35.63547836623698,139.6646043857869],
                    [35.63408348226673,139.66266247355668],
                    [35.633574032220324,139.66189357778853],
                    [35.63314609167668,139.66114139712204],
                    [35.63165302413216,139.65847286653343],
                    [35.63134055232352,139.65776247368467],
                    [35.63033782350594,139.65370717414294],
                    [35.62782839204114,139.64625194613825],
                    [35.62610906305183,139.6420834132964],
                    [35.62419859233498,139.6379820990627],
                    [35.62111795973394,139.63120613321666],
                    [35.61809964188225,139.6284817972878],
                    [35.617610472127524,139.62813913721232],
                    [35.613395182556644,139.62719310702937],
                    [35.61270215041705,139.6269841679955],
                    [35.61206346842678,139.62651614449942],
                    [35.60988249836957,139.62473839345375],
            ], {color: "#a434ebaa", weight: 20})
            , "玉川通り", "Tamagawa-dori Ave.", [35.658048548326164,139.70316563251652], "#a434eb", "たまがわ"],

            "route_Setagaya": [
                L.polyline([
                    [35.643691143535236,139.6715030843236],
                    [35.64344708366984,139.6698479707917],
                    [35.643072857098204,139.66806605419924],
                    [35.64301319762288,139.66617735611896],
                    [35.643246411661366,139.66510286708194],
                    [35.643300647381366,139.66343440588275],
                    [35.64306743349918,139.66225980915078],
                    [35.64253049662517,139.66085162787954],
                    [35.64195758163393,139.65891942343615],
                    [35.64186537932228,139.65799175899565],
                    [35.64223961153313,139.65592286708215],
                    [35.642618114103186,139.6508875979564],
                    [35.64288387087238,139.6484382969029],
                    [35.64295980120701,139.64777758615037],
                    [35.64094668953436,139.64269951496658],
                    [35.640409738417205,139.64137141979404],
                    [35.640263296581296,139.64090425061647],
                    [35.640187363663955,139.63899553098213],
                    [35.64005176899417,139.63741382977776],
                    [35.6397860027745,139.63476431341485],
                    [35.63985108845766,139.63310252602764],
                    [35.63985651225185,139.63170769241697],
                    [35.63959616920111,139.6297589297326],
                    [35.63868993406031,139.62628284608525],
                    [35.637019104820155,139.62113986502345],
                    [35.63524732225338,139.61658818271826],
                    [35.635144263955866,139.6160876443505],
                    [35.63504662966059,139.61458602923062],
                    [35.6346886362243,139.61180303594824],
                    [35.63432521699947,139.6098075563268],
                    [35.63207327095331,139.6047192555626],
                    [35.63180747821194,139.60411193564983],
                    [35.63176408338262,139.60336446501856],
                    [35.63249750463416,139.60027150272947],
                    [35.63507523355021,139.59480411128834],
                    [35.63519456428885,139.59417676984958],
                    [35.63514574718273,139.5933759084046],
                    [35.634923357780394,139.59278861002144],
                    [35.634603332927014,139.59230141934688],
                    [35.63374088668522,139.5913804287053],
                    [35.6334262813251,139.59070637034915],
                    [35.633041159297306,139.5890178875809],
                    [35.63273739976155,139.58570766054982],
                    [35.63272112689542,139.58469323611382],
                    [35.63150545417943,139.58208253670387],
                    [35.62883166416229,139.57569124341103],
                    [35.62860383262438,139.57413623751813],
                    [35.62839227419289,139.57337541919696],
                    [35.62801255252049,139.5727881208095],
                    [35.627258528429465,139.57187380403025],
                    [35.62550208941079,139.56936525326228],
            ], {color: "#0a4da6aa", weight: 20})
            , "世田谷通り", "Setagaya-dori Ave.", [35.643691143535236,139.6715030843236], "#0a4da6", "せたがや"],

            "route_ShinOmeKaido": [
                L.polyline([
                    [35.722625905014354,139.6840639954754],
                    [35.72245118593855,139.6828086038238],
                    [35.72243371400167,139.68196928478926],
                    [35.72259096121881,139.6813666967662],
                    [35.72359267694979,139.67829636748888],
                    [35.723732450233285,139.67787312108726],
                    [35.72377321739302,139.67745704841627],
                    [35.72350401304048,139.67398387769595],
                    [35.72400125464435,139.67064612366767],
                    [35.72383766622967,139.6618739382784],
                    [35.723802722974995,139.66143634458467],
                    [35.72328439624849,139.65980074856918],
                    [35.72285924933513,139.6578925532434],
                    [35.722806833803475,139.65756256457757],
                    [35.722777714038855,139.655754800585],
                    [35.722882545097804,139.65375334758755],
                    [35.72298737601385,139.65272033957592],
                    [35.72384369158479,139.6493667618638],
                    [35.72451925805659,139.64586601254746],
                    [35.725905316191316,139.64113856630024],
                    [35.72718815924415,139.63289819815054],
                    [35.72733781361822,139.62507311575246],
                    [35.72758444497863,139.6191388956347],
                    [35.72810250720936,139.608700991859],
                    [35.72911418588711,139.60402824724588],
                    [35.72921900860135,139.60313153890843],
                    [35.729424238375785,139.59950220487633],
                    [35.72980944841704,139.59302095338342],
                    [35.72989097649416,139.592353802336],
                    [35.73014138364384,139.59170099867356],
                    [35.73152152099284,139.58912171778206],
                    [35.73178356961485,139.58846891408575],
                    [35.731894212106795,139.5877658947195],
                    [35.73174105667766,139.58224393600813],
                    [35.731430138724775,139.57726544363004],
                    [35.73130784892052,139.5717107017781],
                    [35.73156640612373,139.56825632767425],
                    [35.73171510983512,139.56623125045576],
                    [35.73205921020214,139.5619424314641],
                    [35.732032221994174,139.55748737899034],
                    [35.73203222199169,139.55463647800525],
                    [35.73182981012417,139.55047233395965],
                    [35.73160040940077,139.54793727619676],
                    [35.73136426091334,139.5453440368218],
                    [35.73170161571862,139.54324949727777],
                    [35.73178258067047,139.54152898265374],
                    [35.7319580044401,139.53895236651988],
            ], {color: "#c98434aa", weight: 20})
            , "新青梅街道", "Shin-ome-kaido Ave.", [35.722625905014354,139.6840639954754], "#c98434", "しんおうめ"],

            "route_Shinozaki": [
                L.polyline([
                    [35.682168490188275,139.8894491299892],
                    [35.68247386570114,139.89071688923013],
                    [35.683418391839965,139.89291142414004],
                    [35.683794778849915,139.8946775438742],
                    [35.68487421849727,139.8957529534091],
                    [35.68559857113965,139.89701196949227],
                    [35.686138280584075,139.8975453026876],
                    [35.686443640896144,139.89815732439934],
                    [35.68701912792304,139.89882197538066],
                    [35.68733868748021,139.89900558189015],
                    [35.68780737250455,139.89960886041354],
                    [35.68819084003394,139.90070175629285],
                    [35.68861691290229,139.90131377801117],
                    [35.689291523620355,139.90212689253894],
                    [35.690157857440695,139.90396295769767],
                    [35.69115910037128,139.90537935075963],
                    [35.69197570764199,139.90633235598716],
                    [35.692238440385665,139.90669082583827],
                    [35.692465668003166,139.90679574383955],
                    [35.69346688195816,139.90824710959305],
                    [35.6936728038431,139.90883290179113],
                    [35.69422666006798,139.90935749182654],
                    [35.69637814193784,139.91094874824853],
                    [35.69762072053031,139.91152579725576],
                    [35.69817454932614,139.9119017534606],
                    [35.69952360335688,139.9124525730101],
                    [35.69984311280932,139.91253126150787],
                    [35.700262023271065,139.91275858386518],
                    [35.70119923870416,139.91286350188022],
                    [35.701575541800295,139.91311705373118],
                    [35.703520929470045,139.913825250316],
                    [35.70570765892664,139.9133880920033],
                    [35.706389224685694,139.91278481350128],
                    [35.70657381442027,139.91172689030714],
                    [35.70760324927046,139.90986459578562],
                    [35.708348693793326,139.90889410427212],
                    [35.70882435476139,139.90801978759498],
                    [35.709158025678576,139.90782743792693],
                    [35.70950589387553,139.9078798969199],
                    [35.70980480492041,139.90768754726867],
                    [35.710181067401294,139.9072766184297],
                    [35.710337251304054,139.90721541627522],
                    [35.71119625729925,139.90723290264145],
                    [35.711537017446176,139.90711924149184],
                    [35.71276516168987,139.90614875000608],
                    [35.713347281252936,139.90586896868047],
                    [35.71515750398996,139.9034033956981],
                    [35.71626491407078,139.90197825949883],
                    [35.718053774790405,139.89991487212274],
                    [35.71923212935441,139.89850722229244],
                    [35.720020055516684,139.8976941078164],
                    [35.721269363898045,139.89672361633473],
                    [35.72265351792974,139.89593673131014],
                    [35.7244351371951,139.89544711397684],
                    [35.72691230255883,139.89448536562895],
                    [35.72892095029579,139.89400449148798],
                    [35.7309792293418,139.89387334401522],
                    [35.732334825459034,139.89384711456046],
                    [35.73431494794822,139.89342744256862],
            ], {color: "#1d9ed1aa", weight: 20})
            , "篠崎街道", "Shinozaki-kaido Ave.", [35.682168490188275,139.8894491299892], "#1d9ed1", "しのざき"],

            "route_Okubo": [
                L.polyline([
                    [35.70257552970523,139.74466291675526],
                    [35.70297243487883,139.7423837784961],
                    [35.703485820194444,139.74112998686996],
                    [35.703278740771665,139.7393343021945],
                    [35.70153645465686,139.73738004193888],
                    [35.700790085202996,139.7357012361515],
                    [35.69955936734959,139.73222137794346],
                    [35.69911067251914,139.7300484932075],
                    [35.69943856512171,139.72811467895463],
                    [35.699391107037215,139.72607461116468],
                    [35.699829004970525,139.72208115792364],
                    [35.70078160679816,139.71927939653915],
                    [35.70126049206684,139.71759527808808],
                    [35.701169892357214,139.71673462445005],
                    [35.70140286277119,139.7142748551846],
                    [35.70135540586894,139.71220291132067],
                    [35.70126049198595,139.71137944651923],
                    [35.70083337820978,139.7097431421264],
                    [35.7008376924871,139.70885592519323],
                    [35.70144408075714,139.70035831389566],
                    [35.701521737374534,139.69969422930683],
                    [35.70194624087051,139.6941606856392],
                    [35.70196349778601,139.6937144208125],
                    [35.7017305289658,139.69066884037716],
                    [35.701573637431,139.68596079741747],
                    [35.70149166659452,139.6853339015854],
                    [35.70104696471458,139.68283326188316],
                    [35.700857136138325,139.6815529068317],
                    [35.701909205695586,139.67574742672036],
                    [35.70218963039258,139.67443519562084],
                    [35.702306114208966,139.67338328565575],
                    [35.702461425687915,139.67195417568405],
                    [35.702599480081744,139.6699034824759],
                    [35.70295127275415,139.66687102618903],
                    [35.70299010033975,139.6662122542864],
                    [35.70274850614578,139.66563848522526],
                    [35.702411998731705,139.66538347675646],
                    [35.70167426596612,139.66512846825196],
                    [35.70045332481298,139.66437406819932],
                    [35.70034115260577,139.6641456230759],
                    [35.70022466589526,139.66081988747123],
                    [35.70135688034725,139.65478859330074],
            ], {color: "#910622aa", weight: 20})
            , "大久保通り", "Okubo-dori Ave.", [35.70257552970523,139.74466291675526], "#910622", "おおくぼ"],

            "route_Komazawa": [
                L.polyline([
                    [35.64896747045999,139.7112927541798],
                    [35.647966367148854,139.70959349450504],
                    [35.64658551444923,139.70826240775273],
                    [35.64622879027458,139.7075331421354],
                    [35.6463035873861,139.70566395651576],
                    [35.646476195859194,139.70434703026535],
                    [35.6466257895806,139.70245660385308],
                    [35.6462518047197,139.70168485675003],
                    [35.64581452788926,139.7014795295437],
                    [35.644353085333904,139.70137332583533],
                    [35.64267296874179,139.70075734422744],
                    [35.64197099175675,139.7003962515496],
                    [35.64135531833148,139.69959618348435],
                    [35.64111940483025,139.69933421428937],
                    [35.63975569606367,139.69835713998418],
                    [35.638340176286306,139.6976207941168],
                    [35.63689010563509,139.69579408996094],
                    [35.63665993326346,139.695325233179],
                    [35.63220281143505,139.6866202819533],
                    [35.63015861712846,139.6829826694377],
                    [35.62950256888689,139.68175778643536],
                    [35.62884076038202,139.68063202687654],
                    [35.62750561699349,139.67673080982468],
                    [35.626346404737006,139.6721772448882],
                    [35.62476484520974,139.66588810700569],
                    [35.621720746086936,139.65403905113612],
                    [35.620720122671244,139.64916345379595],
                    [35.619650657686506,139.6438260352718],
                    [35.619057834684625,139.64224713978547],
                    [35.61712788074176,139.64006236882096],
                    [35.61486585218668,139.63685679104776],
                    [35.61440537995996,139.63538409932096],
                    [35.614129095349,139.63459819175614],
                    [35.613783738240954,139.63419461759736],
                    [35.61348442753689,139.63404593239625],
                    [35.61282248644521,139.63407425341984],
                    [35.612321709987114,139.63429374114048],
                    [35.611918784122324,139.6342229386638],
                    [35.611642490908665,139.63384060525027],
                    [35.61109565781268,139.632516598743],
                    [35.61073301906991,139.6318014936378],
                    [35.60976597439304,139.63003143147944],
            ], {color: "#40bdd6aa", weight: 20})
            , "駒沢通り", "Komazawa-dori Ave.", [35.64896747045999,139.7112927541798], "#40bdd6", "こまざわ"],

            "route_Inokashira": [
                L.polyline([
                    [35.65945885328162,139.70055314497927],
                    [35.660420394435775,139.70083012204373],
                    [35.66064884398432,139.69957952801792],
                    [35.660761363659276,139.69876118629531],
                    [35.66094548645097,139.69835411372617],
                    [35.661129608813596,139.6981736588756],
                    [35.66200588907489,139.6975315753209],
                    [35.66222069560237,139.69721682846398],
                    [35.66242527271059,139.69683073901138],
                    [35.663509522681565,139.69553398215635],
                    [35.666044708268544,139.6937109388433],
                    [35.66634814960592,139.6933164561625],
                    [35.66652203120091,139.69290938359688],
                    [35.667858520203964,139.69185183425986],
                    [35.66753121885125,139.69044176874286],
                    [35.66775623868793,139.6887295462759],
                    [35.668229244157004,139.6792046607652],
                    [35.668499288788496,139.6729045189859],
                    [35.66902183318517,139.66143878849005],
                    [35.66913093175549,139.65941601581994],
                    [35.669233211527185,139.65914743192388],
                    [35.67056965519109,139.65853472486697],
                    [35.6706378404932,139.65831650042435],
                    [35.66997644063781,139.65636087375765],
                    [35.66986052463308,139.65625176155265],
                    [35.66964232935021,139.65626015481575],
                    [35.669485501118416,139.65615943585453],
                    [35.66936958441073,139.6556810207893],
                    [35.67037191754606,139.6530875073164],
                    [35.672281088696494,139.65155993626956],
                    [35.67271064598215,139.6503177357156],
                    [35.67466067031849,139.64649880816125],
                    [35.67539021263032,139.6453489332671],
                    [35.68003865837433,139.63630194867233],
                    [35.68466537604084,139.62715329529522],
                    [35.68632443831872,139.62274998876455],
                    [35.688145650351714,139.6177946237923],
                    [35.6920934691083,139.60708926880034],
                    [35.69782009205275,139.59143571081415],
                    [35.6994648394994,139.58701725322567],
                    [35.70290826150247,139.57720760343443],
                    [35.703346242288234,139.57576255022138],
                    [35.70679613009753,139.56417953909022],
                    [35.70737456654909,139.56103502184703],
                    [35.7084983739541,139.55446104736848],
                    [35.70874627052787,139.55344340426814],
                    [35.709118113919544,139.55240540829732],
                    [35.71229966275297,139.54389429304183],
                    [35.713101153936194,139.54164530175558],
            ], {color: "#305437aa", weight: 20})
            , "井ノ頭通り", "Inokashira-dori Ave.", [35.65945885328162,139.70055314497927], "#305437", "いのかしら"],

            "route_Tamazutsumi": [
                L.polyline([
                    [35.56250435905481,139.72563553969792],
                    [35.56265799742464,139.72500325124338],
                    [35.56283196428625,139.71940169843947],
                    [35.56311919980608,139.71937706385484],
                    [35.564087777104035,139.71616635242833],
                    [35.56414789531557,139.71468006407952],
                    [35.5644418059118,139.71166642954358],
                    [35.56466891790063,139.71036079494752],
                    [35.56438168796652,139.70788091037298],
                    [35.56413453578742,139.7046127180962],
                    [35.563693667858345,139.69917667970822],
                    [35.56302919715263,139.6912798929186],
                    [35.563156114990036,139.69078720061225],
                    [35.5634099500558,139.69055727755358],
                    [35.5643384451423,139.68994962372858],
                    [35.56873267688378,139.68629397231052],
                    [35.571230739442484,139.68791985701876],
                    [35.57162917970109,139.68721208775992],
                    [35.57207231519719,139.6868605685779],
                    [35.57195876978625,139.68643356860355],
                    [35.571985486367865,139.68596551095243],
                    [35.572566569862516,139.6842985686984],
                    [35.572847091414744,139.68378945333734],
                    [35.57603869430304,139.68066708680908],
                    [35.57823757914227,139.6785230387767],
                    [35.57996154286133,139.6768584983381],
                    [35.58229395029316,139.6745827416039],
                    [35.58266793338332,139.67410647240263],
                    [35.58311537515398,139.67309645318892],
                    [35.58300852362373,139.67292401088707],
                    [35.582975132491455,139.6726858762945],
                    [35.58313540981277,139.67240668398836],
                    [35.583295686812306,139.67183187631372],
                    [35.584971897853194,139.67053445326974],
                    [35.585846719169325,139.66995964558555],
                    [35.58658797250611,139.66911385712433],
                    [35.586968613444725,139.66858010716237],
                    [35.58741603117403,139.66821879947827],
                    [35.58800368051526,139.6675865110417],
                    [35.58828414800158,139.66711845334817],
                    [35.589539561840795,139.6656567995699],
                    [35.59011384032104,139.66524622267036],
                    [35.590708147449476,139.6644168572834],
                    [35.590868409291915,139.66405554959317],
                    [35.59112883410343,139.66345610732787],
                    [35.59140929064283,139.6628073957886],
                    [35.5915695510775,139.66222437658553],
                    [35.591876716032715,139.66051637661394],
                    [35.59220391218696,139.65813503050447],
                    [35.592471010095636,139.65688687669058],
                    [35.59283826825305,139.65583579981126],
                    [35.5935527474787,139.6542017036884],
                    [35.594641826730026,139.651964406954],
                    [35.59645801953893,139.6487044262533],
                    [35.598481156393476,139.64535411859902],
                    [35.60209160289908,139.6398321606148],
                    [35.60390095017071,139.63739333371154],
                    [35.605890517300566,139.63453571840807],
                    [35.6070121306515,139.63296731460605],
                    [35.6083874207919,139.63130037234532],
                    [35.60914849353834,139.6305202762378],
                    [35.61023668146291,139.6295595262958],
                    [35.61105781959877,139.62864804556148],
                    [35.61155850944927,139.6278515263516],
                    [35.61205919617697,139.62654589175486],
                    [35.61332758851363,139.62377860336562],
                    [35.61386831807597,139.6229574495252],
                    [35.61458260955226,139.62232516107667],
                    [35.61517005944083,139.62209523799993],
                    [35.61583093540304,139.6219802764831],
                    [35.616418376125736,139.62161075727943],
                    [35.61731955521249,139.62078960343115],
                    [35.617659998002566,139.62032154573876],
                    [35.61956341417496,139.61576027008005],
                    [35.62051128351442,139.6125906163013],
                    [35.62202651607295,139.60979869325774],
                    [35.62463638674113,139.6078771933037],
                    [35.62588455565215,139.6066126163886],
                    [35.6266187635613,139.60538088567364],
                    [35.626805651775285,139.60482250106884],
                    [35.62702591231617,139.6037221549302],
                    [35.62721947411704,139.60345938569333],
                    [35.630216284846085,139.60067567419992],
                    [35.630623415285655,139.60045396266958],
                    [35.632492184101395,139.60024046268495],
            ], {color: "#027a0aaa", weight: 20})
            , "多摩堤通り", "Tamazutsumi-dori Ave.", [35.56250435905481,139.72563553969792], "#027a0a", "たまづつみ"],

            "route_DokanYama": [
                L.polyline([
                    [35.729899903521186,139.76242999957776],
                    [35.730872285090555,139.76397008951676],
                    [35.73139088374266,139.76482569500672],
                    [35.73175204866506,139.76549877135494],
                    [35.73212247252398,139.7664228253218],
                    [35.73293739896435,139.76806558789144],
                    [35.735153341835925,139.77336228337685],
                    [35.73562561178598,139.7745715391933],
                    [35.73576451417853,139.77490237333245],
                    [35.73702388481196,139.77662499239455],
            ], {color: "#84b091aa", weight: 20})
            , "道灌山通り", "Dokan-yama-dori Ave.", [35.729899903521186,139.76242999957776], "#84b091", "どうかんやま"],

            "route_Kasaibashi": [
                L.polyline([
                    [35.673835346348156,139.79169687981425],
                    [35.67501677404053,139.79538660490607],
                    [35.67540011697113,139.8069391740537],
                    [35.67589799850009,139.82040310531738],
                    [35.675810486920696,139.8215746603246],
                    [35.674809618297516,139.8267824696693],
                    [35.67405481738086,139.82997394721565],
                    [35.67337658438767,139.83294996635482],
                    [35.672468618318206,139.8376227203778],
                    [35.67188882733238,139.84090846097342],
                    [35.6715497023813,139.84322463878425],
                    [35.67127621345634,139.84683356698292],
                    [35.670969904753264,139.85174871170966],
                    [35.67022600727036,139.85666385649165],
                    [35.66765037076847,139.8734069543808],
                    [35.666184387532695,139.88193102724335],
                    [35.66574677538159,139.88478585105509],
                    [35.66552796842055,139.8871289611559],
            ], {color: "#6de892aa", weight: 20})
            , "葛西橋通り", "Kasaibashi-dori Ave.", [35.673835346348156,139.79169687981425], "#6de892", "かさいばし"],

            "route_Ikegami": [
                L.polyline([
                    [35.60963472939885,139.74244217459318],
                    [35.60869184809219,139.74116645541994],
                    [35.60730893539514,139.7399583880308],
                    [35.6068217672791,139.7391369022041],
                    [35.60615387068998,139.73716533617335],
                    [35.606067436625466,139.73693338721762],
                    [35.6052345215725,139.73585095885414],
                    [35.604998789324256,139.73559001631145],
                    [35.604417313470165,139.73511645390295],
                    [35.604228725792254,139.73483618226317],
                    [35.603411507416936,139.73258434470188],
                    [35.60189491655551,139.7313472837102],
                    [35.60048831179171,139.7306417723588],
                    [35.59826441135728,139.73058378514065],
                    [35.59693632826813,139.72979129295163],
                    [35.5954117560199,139.7295883376413],
                    [35.59381642511222,139.72961733126522],
                    [35.592574716965345,139.7293563887422],
                    [35.591678789003964,139.7287861809391],
                    [35.59162377558226,139.72871852916205],
                    [35.58740866618574,139.7269015958584],
                    [35.586080402985004,139.72608011003604],
                    [35.583142020561354,139.7231628528325],
                    [35.58304770173463,139.72305654301977],
                    [35.57988795617953,139.72061141451138],
                    [35.57898402594341,139.71889112655754],
                    [35.577018925041216,139.7151509498815],
                    [35.57481061910684,139.71041019902623],
                    [35.57433897472562,139.70943408056985],
                    [35.57308124275664,139.7051430251804],
                    [35.57290830307831,139.70359669894424],
                    [35.5728296940089,139.7027075613787],
                    [35.57279038945609,139.70161546848692],
                    [35.57247595224588,139.69734374215614],
                    [35.57186279615863,139.68850664211823],
                    [35.571611243570274,139.68821670594878],
            ], {color: "#390b9caa", weight: 20})
            , "池上通り", "Ikegami-dori Ave.", [35.60963472939885,139.74244217459318], "#390b9c", "いけがみ"],

            "route_Kanni": [
                L.polyline([
                    [35.67042409530691,139.74556299463325],
                    [35.669162389578645,139.7470515270295],
                    [35.667949229225016,139.74799275699797],
                    [35.666963982390214,139.74877108182307],
                    [35.666397827597606,139.74955845691738],
                    [35.66512580285065,139.75338672883663],
                    [35.66458169358836,139.75472617151627],
                    [35.66422875586075,139.7557126529432],
                    [35.66369934635583,139.75737790596918],
                    [35.66363316993417,139.7579480741265],
                    [35.66370669932613,139.75874449953744],
                    [35.66349346396923,139.7605183560454],
                    [35.66380005023433,139.7631397578548],
                    [35.663370486790654,139.76404614054547],
                    [35.66297160437057,139.76539492433983],
                    [35.66300228770139,139.76639302434876],
                    [35.66237580728736,139.76739657504982],
                    [35.661728735736915,139.76823825186636],
                    [35.66123607546761,139.76872696744488],
                    [35.66025074578978,139.76991255522557],
                    [35.65841241164024,139.77165926088196],
                    [35.656551974402774,139.77358697227453],
                    [35.65577248979537,139.77440149824508],
                    [35.65511065727256,139.77544228141164],
                    [35.65269124502021,139.77790395981856],
                    [35.65091701475397,139.77955267860307],
                    [35.64880635691483,139.78138988711976],
                    [35.64681328417758,139.78318081537395],
                    [35.645048187286974,139.78472841467283],
                    [35.63930798727962,139.78943259721143],
                    [35.63561464169422,139.79244316570907],
            ], {color: "#7d1404aa", weight: 20})
            , "環二通り", "Kanni-dori Ave.", [35.67042409530691,139.74556299463325], "#7d1404", "かんに"],

            "route_Ariake": [
                L.polyline([
                    [35.65527483171735,139.7817908389294],
                    [35.65392433407858,139.783117210019],
                    [35.64898125094717,139.78816660716143],
                    [35.648003229614304,139.78908360444893],
                    [35.64452708103278,139.79192354730753],
                    [35.64184696045996,139.79402525086243],
                    [35.63811086675976,139.79707792329236],
            ], {color: "#bf3442aa", weight: 20})
            , "有明通り", "Ariake-dori Ave.", [35.65527483171735,139.7817908389294], "#bf3442", "ありあけ"],

            "route_KiyosunaOhashi": [
                L.polyline([
                    [35.666039046934785,139.83794388259457],
                    [35.66613966279492,139.83934746795126],
                    [35.665829430173986,139.8419688700235],
                    [35.66489834280888,139.85295828734843],
                    [35.664680338241794,139.85374264387815],
                    [35.66291951021019,139.85686974947419],
                    [35.662542184878916,139.8578089131878],
                    [35.66029934826591,139.8728778775369],
                    [35.65904102836522,139.8814038063117],
                    [35.6585714448767,139.884510270933],
            ], {color: "#348ebfaa", weight: 20})
            , "清砂大橋通り", "Kiyosuna-ohashi-dori Ave.", [35.666039046934785,139.83794388259457], "#348ebf", "きよすなおおはし"],

            "route_Kohokubashi": [
                L.polyline([
                    [35.762501268564264,139.75850798735746],
                    [35.76468875785764,139.76354665065617],
                    [35.76510023298644,139.76463080341114],
                    [35.76505766670397,139.765618781292],
                    [35.76488030692778,139.77077724980632],
                    [35.76456815272877,139.77762314952565],
                    [35.76438267308757,139.78197239279916],
                    [35.7640644201701,139.78305151771235],
                    [35.76394324056339,139.7869471397969],
                    [35.76346663049861,139.79379837511306],
                    [35.76345379414815,139.7988368117958],
                    [35.763479466798415,139.80146281025588],
                    [35.76362066630321,139.8037882426372],
                    [35.763652757056065,139.80419954363322],
                    [35.763434539563576,139.81318995830327],
                    [35.76382604686361,139.8187820694783],
                    [35.76432024188151,139.82528378858115],
                    [35.764589801496484,139.82914369001276],
                    [35.76444860366472,139.83554258378604],
                    [35.76433949608689,139.83619117382727],
                    [35.76310079381717,139.83772564289075],
                    [35.762966011577106,139.83820813056718],
                    [35.763088952428916,139.84586661772153],
                    [35.76306969779536,139.84618300310967],
                    [35.76282580585031,139.84714797842526],
                    [35.76281938763272,139.84763046606395],
                    [35.76308253417086,139.8517592949413],
            ], {color: "#9e10adaa", weight: 20})
            , "江北橋通り", "Kohokubashi-dori Ave.", [35.762501268564264,139.75850798735746], "#9e10ad", "こうほくばし"],

            "route_Hatogaya": [
                L.polyline([
                    [35.768939690064386,139.76169244325206],
                    [35.76908224715967,139.76186814034668],
                    [35.77045180057115,139.7618995148464],
                    [35.77071654422812,139.76187441525656],
                    [35.77191777695995,139.76171754285554],
                    [35.77508794387228,139.76130085094087],
                    [35.775968670915034,139.7610624049271],
                    [35.77679847995863,139.76091180745368],
                    [35.77755191870243,139.76063571206237],
                    [35.7782442615179,139.7605353137585],
                    [35.783058420536236,139.76097925117438],
                    [35.784051047317746,139.7611298486877],
                    [35.786876358489025,139.76171035439052],
                    [35.78724794082807,139.7617291791007],
                    [35.78958428868009,139.76220607121263],
                    [35.79015436749671,139.76216842185806],
                    [35.7910807368409,139.76198644991632],
                    [35.79159481627143,139.76193625075825],
                    [35.796089850975164,139.76168794188334],
                    [35.79724009022134,139.76159381844855],
                    [35.79909774230073,139.76115457579658],
                    [35.80368358491795,139.75985820194205],
                    [35.80512887741163,139.7594440588747],
                    [35.80578535722057,139.75893579236424],
                    [35.80721487148939,139.7577146685494],
                    [35.80937012744799,139.75583734918212],
                    [35.810041838307725,139.7551784852003],
                    [35.810703366163764,139.75441294800225],
            ], {color: "#e62731aa", weight: 20})
            , "鳩ヶ谷街道", "Hatogaya-kaido Ave.", [35.768939690064386,139.76169244325206], "#e62731", "はとがや"],

            "route_Kokusai": [
                L.polyline([
                    [35.728849199101646,139.79073426202785],
                    [35.72136648265517,139.7911752859452],
                    [35.71866287052384,139.79267082423337],
                    [35.71727055544656,139.79268079452015],
                    [35.714526328304586,139.79253124072466],
                    [35.7113934225259,139.79199284697393],
                    [35.709644770408104,139.79139463170486],
                    [35.708349447813276,139.7911254348371],
                    [35.706066390486754,139.7907465651747],
                    [35.702832549576186,139.7899533341067],
                    [35.70197432738023,139.78946479162016],
            ], {color: "#2770e6aa", weight: 20})
            , "国際通り", "Kokusai-dori Ave.", [35.728849199101646,139.79073426202785], "#2770e6", "こくさい"],

            "route_Jiyu": [
                L.polyline([
                    [35.63317035143372,139.66115796362965],
                    [35.631907254209125,139.66161632526052],
                    [35.62936853261563,139.66298273518896],
                    [35.62731371451554,139.66448269796263],
                    [35.6247728393501,139.66587135205327],
                    [35.62048498284504,139.66715112123188],
                    [35.614971765289255,139.66971329807097],
                    [35.61364095217422,139.66987045051474],
                    [35.61231703738329,139.66992759450773],
                    [35.61090810245864,139.66991013305164],
                    [35.60982210544387,139.6699144982938],
                    [35.60979371315659,139.67020261132598],
                    [35.608421641620225,139.67061510251483],
                    [35.607352468673625,139.6709474150956],
                    [35.60557688315254,139.67144471572806],
                    [35.60509773670901,139.67155821478286],
                    [35.60380580165172,139.67183323172753],
                    [35.60351475844692,139.67192053862925],
                    [35.602489000205736,139.67217372881305],
                    [35.59946762106135,139.67313037322015],
                    [35.59336749589687,139.67862641655108],
                    [35.59195062848468,139.67967305458797],
                    [35.59142170258826,139.68036714519803],
            ], {color: "#615fedaa", weight: 20})
            , "自由通り", "Jiyu-dori Ave.", [35.63317035143372,139.66115796362965], "#615fed", "じゆう"],

            "route_Kichijoji": [
                L.polyline([
                    [35.72320896168796,139.58169851962953],
                    [35.72006670569061,139.58268933243994],
                    [35.71628605697019,139.5830196034392],
                    [35.71475290779573,139.58342885220623],
                    [35.71358116553185,139.58256009609454],
                    [35.709032071405645,139.58028245750802],
                    [35.70727724042483,139.57943524081122],
                    [35.7029304352927,139.57724003161638],
                    [35.702417359728074,139.5769671991278],
                    [35.70211417715072,139.57670154644597],
                    [35.70171770589474,139.5754953395949],
                    [35.701058859609276,139.57421733472745],
                    [35.70062739979632,139.57334857859814],
                    [35.69995105266143,139.57169004421496],
                    [35.699502094945416,139.57064179302074],
                    [35.69920473194675,139.570347421135],
                    [35.69899482798046,139.5702612635249],
                    [35.69123306517034,139.56932033627072],
                    [35.682774364665406,139.56822727496694],
                    [35.682418620465114,139.5681985557551],
                    [35.6810306197808,139.5701012034442],
                    [35.67824572838305,139.57384721996846],
                    [35.677079281863115,139.5775017395648],
                    [35.6741283237164,139.58100945058038],
                    [35.67370255074955,139.58152639639388],
                    [35.67331177080791,139.58228027569726],
                    [35.67236105939804,139.5845131943845],
                    [35.66927977223921,139.59176930400668],
                    [35.668095695882585,139.59273139760342],
            ], {color: "#1fd7f0aa", weight: 20})
            , "吉祥寺通り", "Kichijoji-dori Ave.", [35.72320896168796,139.58169851962953], "#1fd7f0", "きちじょうじ"],

            "route_Kawanote": [
                L.polyline([
                    [35.72815168483805,139.8085006504973],
                    [35.729950437641165,139.8088610043227],
                    [35.731176553818315,139.80925202654362],
                    [35.73283831566857,139.8099727341912],
                    [35.73372830868499,139.8104250932554],
                    [35.73463696260406,139.81103846147585],
                    [35.73664716701181,139.81256421487865],
                    [35.737238393944935,139.81298590552842],
                    [35.737730042465,139.81320058438018],
                    [35.73829014467941,139.8132619211841],
                    [35.739018271663696,139.81316224887632],
                    [35.739379221028784,139.81304724233974],
                    [35.73966549004558,139.81277889377304],
                    [35.739796177733496,139.81251821229375],
                    [35.739970427648956,139.81176683626438],
                    [35.741694236695096,139.81251054520362],
                    [35.74217341164139,139.81280189511676],
                    [35.74387228139983,139.8143353156388],
                    [35.74428921457762,139.8149793522873],
                    [35.74659641881614,139.82163881894996],
                    [35.74711912277317,139.82318757364567],
                    [35.747268466115756,139.82409229175892],
                    [35.74691999780748,139.8263310856746],
                    [35.74919745934929,139.8276804956995],
                    [35.74978859307143,139.82799484690963],
                    [35.75406216928178,139.82739926898648],
                    [35.7545661580407,139.82750660846636],
                    [35.75713451700294,139.8283366608498],
                    [35.75895128023164,139.82902670007093],
                    [35.75960455823335,139.82911870530347],
                    [35.764567027569925,139.82913808630929],
                    [35.76884897742009,139.82950041139793],
                    [35.77512825436345,139.83002329055603],
                    [35.777523093689,139.83023030234085],
            ], {color: "#69edffaa", weight: 20})
            , "川の手通り", "Kawanote-dori Ave.", [35.72815168483805,139.8085006504973], "#69edff", "かわのて"],

            "route_Kanamecho": [
                L.polyline([
                    [35.73313441907678,139.69936957915593],
                    [35.733454684807725,139.69715199117763],
                    [35.734288474039076,139.694689516229],
                    [35.73471364796552,139.69404328662813],
                    [35.736873793492535,139.69120563996103],
                    [35.739205477798876,139.6881487984848],
                    [35.739702403429916,139.68735971815545],
                    [35.74067416013946,139.68525777133777],
                    [35.74282951819363,139.6806905746456],
                    [35.7439143225602,139.67845074615957],
                    [35.74552646354712,139.67481825546986],
            ], {color: "#ffd969aa", weight: 20})
            , "要町通り", "Kanamecho-dori Ave.", [35.73313441907678,139.69936957915593], "#ffd969", "かなめちょう"],

            "route_Yurinokibashi": [
                L.polyline([
                    [35.72862312584198,139.82507646656506],
                    [35.72775992372807,139.82605615792363],
                    [35.725994694770876,139.82812306788958],
                    [35.72508296779873,139.82917444399652],
                    [35.72390934643151,139.8303691896259],
                    [35.72346317178588,139.83078735060144],
                    [35.722910299735375,139.83120551156333],
                    [35.718107039847254,139.83463669933917],
                    [35.717078819653665,139.83538938907304],
                    [35.71311131731572,139.8400130545582],
                    [35.71032715738386,139.84142285436587],
                    [35.707824240711716,139.8442663488671],
                    [35.70715484271258,139.8447561945586],
                    [35.70177947535902,139.84773931285144],
                    [35.6991016465669,139.8492685872411],
            ], {color: "#3bcc3baa", weight: 20})
            , "ゆりのき橋通り", "Yurinokibashi-dori Ave.", [35.72862312584198,139.82507646656506], "#3bcc3b", "ゆりのきばし"],

            "route_Awashima": [
                L.polyline([
                    [35.654734773435166,139.692671186624],
                    [35.65532615269419,139.69023922155475],
                    [35.6554271194652,139.68881909598088],
                    [35.65539105993642,139.6880735300315],
                    [35.65536221229702,139.6865912738548],
                    [35.65539105994904,139.68632500030367],
                    [35.65580213773238,139.68510014196795],
                    [35.65583098521742,139.68469185586218],
                    [35.6555785694065,139.68172734364816],
                    [35.655600205078656,139.6812658028382],
                    [35.655679535846886,139.6802983422806],
                    [35.65539827192025,139.6762189454754],
                    [35.654936708456844,139.67383135931027],
                    [35.65474198556893,139.67342307319677],
                    [35.653169761122186,139.67208282966544],
                    [35.65222496916245,139.67128400902925],
                    [35.651590293479856,139.67058282200765],
                    [35.65124410461107,139.66988163499616],
                    [35.650854640351895,139.66732540886983],
                    [35.65071760618373,139.6665443397875],
                    [35.650356988810614,139.66514196576497],
                    [35.65019110427748,139.66258573966184],
                    [35.65005406896914,139.66042892391408],
            ], {color: "#e38c12aa", weight: 20})
            , "淡島通り", "Awashima-dori Ave.", [35.654734773435166,139.692671186624], "#e38c12", "あわしま"],

            "route_Fuji": [
                L.polyline([
                    [35.749205737557915,139.61860873113642],
                    [35.74919899196992,139.61823470624586],
                    [35.74768121775152,139.61461082037124],
                    [35.74740464244121,139.61366329059],
                    [35.745596760675284,139.60885083659014],
                    [35.74532692405117,139.60792824180498],
                    [35.74432177459356,139.6047282508357],
                    [35.743249151442456,139.60134540323338],
                    [35.741777165239625,139.59677040387135],
                    [35.74076522463682,139.59382807451118],
                    [35.74022551775131,139.59210755993365],
                    [35.73914609295092,139.58825925912504],
                    [35.73808014661381,139.5841532966483],
                    [35.736076104838205,139.57654703727303],
                    [35.7347817446815,139.57243901492495],
                    [35.734019350314725,139.5694717505428],
                    [35.73325694865976,139.5662551362509],
                    [35.732912853487605,139.56487539985278],
                    [35.732440563568815,139.56349566348047],
                    [35.73206272962153,139.5619164471591],
                    [35.73135428615929,139.5593730777365],
                    [35.730801021148125,139.5575943814531],
                    [35.72979568830185,139.55451906548274],
                    [35.72920192872361,139.55305621251455],
                    [35.72898601505507,139.55241621432697],
                    [35.7287161221376,139.55135232125554],
                    [35.72854743861238,139.55001414322214],
                    [35.72858792271937,139.54902505512007],
                    [35.72855418598614,139.5475289553575],
                    [35.728452975707086,139.5472380470717],
            ], {color: "#e38c12aa", weight: 20})
            , "富士街道", "Fuji-kaido Ave.", [35.749205737557915,139.61860873113642], "#e38c12", "ふじ"],

            "route_Mitaka": [
                L.polyline([
                    [35.712628200377594,139.5642181667539],
                    [35.70740363442616,139.561052099981],
                    [35.703708164459414,139.5589073380504],
                    [35.701059458539106,139.558973276453],
                    [35.69823245096492,139.55910213502898],
                    [35.69393142248917,139.55927024040773],
                    [35.69305852775712,139.55934936644712],
                    [35.69161260748277,139.55985049798858],
                    [35.690529071418155,139.55966547743037],
                    [35.68829049053194,139.5592039089197],
                    [35.68376384566953,139.55832223642892],
                    [35.68113127551136,139.55784967680984],
                    [35.680306445338346,139.5577046124272],
                    [35.67984582217572,139.55740788982553],
                    [35.67832467563018,139.55614846721232],
                    [35.677944384442156,139.55573305558403],
                    [35.677087383603805,139.5546450726847],
                    [35.67680350004204,139.55438131927667],
                    [35.67624108624564,139.55413734735816],
                    [35.67554475892051,139.5541571288495],
                    [35.674848425505886,139.55438131927917],
                    [35.67230407872364,139.55506707824156],
                    [35.67099705643092,139.55536380086278],
                    [35.67053102280235,139.55536380087187],
                    [35.67001141888893,139.55523192416445],
                    [35.66945967083113,139.55493520155937],
                    [35.66717228623817,139.55355049609705],
                    [35.6658383946979,139.55289770638456],
                    [35.66481519373336,139.5529900200986],
                    [35.664290195462556,139.55295705093897],
                    [35.663781265285394,139.55291748792138],
                    [35.661831229093096,139.55324058587018],
                    [35.660657970084415,139.55249548247036],
                    [35.658579277120026,139.55175037903382],
                    [35.65759884386853,139.5517569728794],
                    [35.65710594390603,139.55190203728625],
                    [35.65444851784267,139.55195478804285],
                    [35.653210855770766,139.55163169009074],
                    [35.65121233931326,139.55111077702952],
            ], {color: "#6e41e8aa", weight: 20})
            , "三鷹通り", "Mitaka-dori Ave.", [35.712628200377594,139.5642181667539], "#6e41e8", "みたか"],

            "route_Hitomi": [
                L.polyline([
                    [35.68443004587559,139.62766050653852],
                    [35.68502465344362,139.6243328135199],
                    [35.68534434091359,139.6232103526056],
                    [35.68629155553968,139.61901934581215],
                    [35.68634483602001,139.6164610095068],
                    [35.68682900801777,139.61359341826005],
                    [35.687130928292376,139.61223042994789],
                    [35.68752164693913,139.61093304004407],
                    [35.68805444202151,139.60846945689718],
                    [35.68809588146965,139.60785720545573],
                    [35.6880603618783,139.60412538727098],
                    [35.68750161785364,139.59962250650574],
                    [35.68683244092534,139.59535025131095],
                    [35.68598938876926,139.59173643736153],
                    [35.685628263089285,139.59043175874137],
                    [35.685622343003345,139.58975390889964],
                    [35.6858709870674,139.5885075398886],
                    [35.685853226808774,139.58770578207032],
                    [35.68559866255447,139.5866999404593],
                    [35.68558090221461,139.58624075192597],
                    [35.685699304289216,139.58452061697494],
                    [35.68596570836354,139.58359495114212],
                    [35.68789563135215,139.58148122602483],
                    [35.68780683223355,139.58057013761322],
                    [35.68736875513166,139.57925088155514],
                    [35.68559793303298,139.576020188239],
                    [35.68520128478434,139.57486128378264],
                    [35.68427773793735,139.5728423118428],
                    [35.68311736906156,139.57112217687336],
                    [35.68240693081293,139.5708160511731],
                    [35.68276807101502,139.56821398262355],
                    [35.6833173045688,139.56343495246858],
                    [35.683708041886305,139.56057777917],
                    [35.68374948359467,139.55831827991915],
                    [35.68376132402817,139.55171098092615],
                    [35.683725802547414,139.5495243686887],
                    [35.68374948353052,139.5476293047691],
                    [35.68361331775887,139.54512198943962],
                    [35.68338834772109,139.54321234809618],
                    [35.6832936232464,139.54170358563147],
                    [35.68289696351252,139.53875894781004],
                    [35.682458859441255,139.53714085478782],
                    [35.68196746960395,139.53477931353393],
                    [35.681476076739,139.533824492843],
                    [35.67981834353603,139.53139735326295],
                    [35.67946311050832,139.530355068046],
                    [35.67893617857045,139.52652849660808],
                    [35.678593444637585,139.52409941580208],
                    [35.67813163429258,139.52068101201223],
                    [35.67798953821868,139.5189098561159],
                    [35.677776393694145,139.51814454184228],
                    [35.67772310746379,139.517175143749],
                    [35.67730273716241,139.51515617175255],
                    [35.67673434562806,139.51233544201887],
                    [35.67627844533739,139.5097042186169],
                    [35.67563899636768,139.5074520079919],
                    [35.67559755040284,139.50578289406508],
                    [35.67546137071717,139.5042959977884],
                    [35.675342953401184,139.50284554502002],
                    [35.67521861503653,139.49899710745376],
            ], {color: "#0933ebaa", weight: 20})
            , "人見街道", "Hitomi-kaido Ave.", [35.68443004587559,139.62766050653852], "#0933eb", "ひとみ"],

            "route_Honan": [
                L.polyline([
                    [35.680046393494536,139.63624840451007],
                    [35.681184374862994,139.6405853209701],
                    [35.68278881541481,139.64745917965683],
                    [35.682828396295434,139.64797083836515],
                    [35.68258101554423,139.64972509671838],
                    [35.68253153930584,139.6520397431998],
                    [35.68285808197777,139.6532945252236],
                    [35.683333050754754,139.65680304187572],
                    [35.68367938037524,139.65861821197268],
                    [35.68609415146635,139.6672313457825],
                    [35.68615352032771,139.669107427595],
                    [35.68643057436218,139.67245757374258],
                    [35.68653941675093,139.67454075560613],
                    [35.68887454482797,139.68225218292196],
                    [35.69107108596556,139.68730785798152],
            ], {color: "#1cd7e8aa", weight: 20})
            , "方南通り", "Honan-dori Ave.", [35.680046393494536,139.63624840451007], "#1cd7e8", "ほうなん"],

            "route_Nakasugi": [
                L.polyline([
                    [35.740770688029755,139.63666967083782],
                    [35.740157133894286,139.6368141588191],
                    [35.739480371641704,139.6371240986232],
                    [35.736869932772805,139.63810322888378],
                    [35.736154164961306,139.63828657347358],
                    [35.73615062153736,139.63833459231694],
                    [35.7356207012476,139.63850442963872],
                    [35.73407353836619,139.63880169737672],
                    [35.73332833452662,139.63893284492724],
                    [35.73225664818135,139.63917765360804],
                    [35.730553275960716,139.63959732562523],
                    [35.729183454390395,139.6394574349772],
                    [35.727962660909185,139.63922136949463],
                    [35.72630888175662,139.63864432051014],
                    [35.725570703163854,139.6382945938367],
                    [35.723058005506346,139.63810224426322],
                    [35.72227010940918,139.6380148125994],
                    [35.72205006496527,139.63790989460176],
                    [35.72170225154045,139.6375776542734],
                    [35.721411222819995,139.63707929376392],
                    [35.72104920999742,139.63684322824747],
                    [35.72050973684662,139.63662464908793],
                    [35.71891968945101,139.63622246341788],
                    [35.717208933399306,139.6360738296147],
                    [35.716676532941534,139.63599514115288],
                    [35.71507221138617,139.6353656331703],
                    [35.714135159070594,139.63479732733572],
                    [35.71353884731508,139.6347186388307],
                    [35.71137291548146,139.63514758869337],
                    [35.706476110374766,139.6363821452935],
                    [35.70510587483081,139.63661821081712],
                    [35.704204204391885,139.63664444033975],
                    [35.69945141965832,139.63551192539762],
            ], {color: "#f21d41aa", weight: 20})
            , "中杉通り", "Nakasugi-dori Ave.", [35.740770688029755,139.63666967083782], "#f21d41", "なかすぎ"],

            "route_Senkawa": [
                L.polyline([
                    [35.72186308370421,139.58737930578684],
                    [35.72221089643929,139.5877028030178],
                    [35.72230317304232,139.58813121820867],
                    [35.722338664015595,139.58848094493226],
                    [35.72259419853734,139.58967875884946],
                    [35.72362342647622,139.59215307518343],
                    [35.72441130921608,139.59489842966377],
                    [35.72473071891888,139.5962099047274],
                    [35.7252772614591,139.59957602402483],
                    [35.72552568863274,139.59957602403745],
                    [35.72558247188776,139.60066017672716],
                    [35.72564635299244,139.60130717111974],
                    [35.725774115048466,139.6018754769933],
                    [35.72641292223959,139.60346673339257],
                    [35.72680330189759,139.60420990259752],
                    [35.72764083725619,139.6074536175313],
                    [35.72812348075068,139.60867766092792],
                    [35.72860612132014,139.60978804315982],
                    [35.72877646435761,139.61064487351814],
                    [35.72910295414725,139.61184268743546],
                    [35.72981270993304,139.61452683971675],
                    [35.73046567966283,139.6165989703502],
                    [35.730983793152035,139.618120281389],
                    [35.73151609801717,139.62059459766442],
                    [35.73231809730899,139.62358476083364],
                    [35.73274393435625,139.62554323026484],
                    [35.73307040789808,139.62659241038068],
                    [35.73355301850164,139.62818366677686],
                    [35.73400014040429,139.62995852966378],
                    [35.73465307581469,139.63061426719173],
                    [35.735057609200325,139.63231918473957],
                    [35.73540536432441,139.63339459428448],
                    [35.73624589770357,139.63903471409208],
                    [35.73658655071918,139.6406871726914],
                    [35.736735585964865,139.64155274630184],
                    [35.736749779785384,139.64394837409486],
                    [35.73636654584499,139.64523361966224],
                    [35.73643751524044,139.64748061355817],
                    [35.73671429526064,139.64964017580428],
                    [35.73696268677021,139.6502434543305],
                    [35.73704075252597,139.65104782571484],
                    [35.737104624447596,139.65269154121052],
                    [35.73739559586534,139.6548073876471],
                    [35.73755172643362,139.65574290655206],
                    [35.738126568147095,139.65856694957438],
                    [35.73809108422539,139.66132104724784],
                    [35.73810527780044,139.66224782293125],
                    [35.738346568208215,139.66409263114997],
                    [35.73848850341531,139.66605110063995],
                    [35.73840334234056,139.66655820434562],
                    [35.737906567559705,139.66769481608105],
                    [35.737580113881066,139.66855164648393],
                    [35.73668478898804,139.6707825323722],
                    [35.73644349356387,139.6715694174252],
                    [35.73619510044867,139.6728284334447],
                    [35.73444922984676,139.6749355367176],
                    [35.73257232032357,139.67686235863755],
                    [35.73103219564106,139.67836618338663],
            ], {color: "#6e2c37aa", weight: 20})
            , "千川通り", "Senkawa-dori Ave.", [35.72186308370421,139.58737930578684], "#6e2c37", "せんかわ"],

            "route_Tenmondai": [
                L.polyline([
                    [35.70239616815432,139.54427592983325],
                    [35.702372257104656,139.543425868226],
                    [35.70229428636824,139.54319158920953],
                    [35.70221839477412,139.54201635346203],
                    [35.70171696628512,139.5419908375427],
                    [35.70088289084812,139.5418881278265],
                    [35.7009662987751,139.54049641063602],
                    [35.70087455003015,139.53856033167813],
                    [35.70062015525562,139.53850897682105],
                    [35.697553378430015,139.5378471653205],
                    [35.69526712742079,139.537857436281],
                    [35.69405761737079,139.5379139266604],
                    [35.692994067507655,139.53764688128445],
                    [35.69005394161823,139.5378793396212],
                    [35.688627458723445,139.53804367525868],
                    [35.68470205603276,139.53875029441497],
                    [35.68434332814293,139.53877597189683],
                    [35.68120154575884,139.53871383595447],
                    [35.679703988985196,139.53860599073244],
                    [35.676815195291724,139.53916974586434],
                    [35.67555534479666,139.53922110076974],
                    [35.675033877664546,139.53928272665178],
                    [35.67427461544824,139.53949841715246],
                    [35.673744796325494,139.53947273972426],
                    [35.67306478708237,139.53908757813474],
                    [35.672635084997054,139.53888215861537],
                    [35.67097466108598,139.53831211950228],
                    [35.66997338374574,139.53775748679698],
                    [35.669397643581604,139.53730042837697],
                    [35.66860495105895,139.53648902126304],
                    [35.66780807852648,139.5355594979568],
                    [35.6672198062531,139.53514352343768],
                    [35.666485502889046,139.53481998768984],
                    [35.6660182154134,139.5345529423247],
                    [35.66496263632517,139.53371585778757],
                    [35.66437851541113,139.53336664460733],
                    [35.661533897105784,139.53237166280064],
                    [35.660753643984236,139.53222273364895],
                    [35.65992748531558,139.53226895304851],
            ], {color: "#d44aa6aa", weight: 20})
            , "天文台通り", "Tenmondai-dori Ave.", [35.70239616815432,139.54427592983325], "#d44aa6", "てんもんだい"],

            "route_Musashisakai": [
                L.polyline([
                    [35.65476593572558,139.53887305926446],
                    [35.65661446901926,139.53977176969715],
                    [35.657069293454086,139.53996178276554],
                    [35.65897201711719,139.5405626348736],
                    [35.660127816160525,139.54075778344009],
                    [35.66062851812623,139.54092211905936],
                    [35.66142963473986,139.54131755165145],
                    [35.66288998289389,139.54233951377785],
                    [35.66486349669003,139.5429146884312],
                    [35.66554774800289,139.54331012101704],
                    [35.66626954334296,139.54377745042746],
                    [35.666715968409974,139.5440136828911],
                    [35.667362654498945,139.54429099927063],
                    [35.6680552286404,139.5444296574458],
                    [35.66971154120303,139.54458372211218],
                    [35.67128855247164,139.54443479298288],
                    [35.67183507555684,139.54446560590156],
                    [35.67223975054378,139.5445734511635],
                    [35.67661723334646,139.54643795225832],
                    [35.68004672585576,139.54790110167414],
                    [35.68186130270942,139.54869196683558],
                    [35.68290414428955,139.54923119306773],
                    [35.68373423645109,139.5495341868575],
                    [35.6846811149362,139.54971906443348],
                    [35.6873019431022,139.55024768117732],
                    [35.688841067507724,139.55056094599456],
                    [35.689716978717115,139.55060202988716],
                    [35.69484409918897,139.55054553957075],
                    [35.695011437362595,139.54670260343846],
                    [35.69615154223307,139.54675940375432],
                    [35.69643436425446,139.5467491328094],
                    [35.69796080079831,139.54663101659116],
                    [35.698407048577934,139.54663101663584],
                    [35.69931204810037,139.54667723604325],
                    [35.69987089059357,139.5466721005928],
                    [35.700742510261534,139.5465899328094],
                    [35.7023230785629,139.54646668112446],
                    [35.70231473790946,139.54604043566178],
                    [35.70241482560563,139.54549607402464],
                    [35.70239397399759,139.544854138054],
                    [35.70247321002327,139.54451519586223],
                    [35.70246686153148,139.54425672664638],
                    [35.70433515873172,139.54410187520375],
                    [35.70606976770516,139.54398496355418],
                    [35.70754180914503,139.5438822538232],
                    [35.7095297836963,139.54371278274903],
                    [35.7109684022784,139.54373846019337],
                    [35.71230691973591,139.54386684741712],
                    [35.71408323528719,139.54400550559183],
                    [35.716788769581456,139.54370251177943],
                    [35.717560135694995,139.543543311681],
                    [35.71896942316073,139.5432095049623],
                    [35.72018272466305,139.5429578660718],
                    [35.72118337178037,139.5429013757414],
                    [35.72127092779159,139.54273704014963],
                    [35.72157528846476,139.5426137884392],
                    [35.72219234486778,139.54234674306156],
                    [35.72308039741006,139.54193590402792],
                    [35.7240017935854,139.54187941366195],
                    [35.72500239271143,139.54164318122662],
                    [35.72586123028522,139.54137613591388],
                    [35.72707025778735,139.54105260019128],
                    [35.72765391967751,139.54100124531442],
                    [35.72915474488117,139.54153020058288],
                    [35.73024448928672,139.5420395067149],
                    [35.730828127905866,139.54228601015473],
                    [35.73114495850757,139.54237331345917],
                    [35.73177027836405,139.54240926189664],
            ], {color: "#2f1ec9aa", weight: 20})
            , "武蔵境通り", "Musashisakai-dori Ave.", [35.65476593572558,139.53887305926446], "#2f1ec9", "むさしさかい"],

            "route_Shinmusashisakai": [
                L.polyline([
                    [35.69484409918897,139.55054553957075],
                    [35.69966553504038,139.55043769433235],
                    [35.70241245452654,139.55027335873706],
                    [35.7042804657691,139.55016464869652],
                    [35.70521928345866,139.5503090869861],
                    [35.705953230348925,139.55057613236204],
                    [35.706770572350166,139.55102291984258],
                    [35.7091352879132,139.5524123432017],
                    [35.71001097621888,139.55289507906923],
                    [35.71065731141546,139.55315185346558],
                    [35.7112494332422,139.55321861480672],
                    [35.71284230255668,139.55310049862305],
                    [35.71345942660051,139.55307995668136],
                    [35.71398898193806,139.55318780197047],
                    [35.71442263093405,139.55335727309387],
                    [35.714985537181846,139.5536808088283],
            ], {color: "#db303caa", weight: 20})
            , "新武蔵境通り", "Shinmusashisakai-dori Ave.", [35.69484409918897,139.55054553957075], "#db303c", "しんむさしさかい"],

            "route_Fushimi": [
                L.polyline([
                    [35.721398893775046,139.55739831044343],
                    [35.72234115576282,139.5575369686901],
                    [35.724718198392154,139.5577028391249],
                    [35.726344151021905,139.55792366515516],
                    [35.7271446077997,139.55791852967945],
                    [35.72785333887283,139.55790825868473],
                    [35.72907067400885,139.5577644650507],
                    [35.729871103397514,139.5577028392072],
                    [35.73203868683761,139.55747529568396],
                    [35.73451824807056,139.5572341903478],
                    [35.7370406571178,139.55698443925758],
                    [35.73822033581686,139.55692281341146],
                    [35.73894981036956,139.5569947102539],
                    [35.73973763536067,139.55713850392397],
                    [35.74135287395258,139.55742095941082],
                    [35.743137466861896,139.5577669906703],
                    [35.74678660241079,139.55845425362213],
                    [35.749474909577955,139.55894246022626],
                    [35.751191786636426,139.55925890120784],
                    [35.752312907759006,139.5595362175543],
                    [35.75333815711241,139.55972109512754],
                    [35.754259203205194,139.55989570172846],
                    [35.75587450889634,139.5602386301856],
                    [35.75746703930872,139.56056216594402],
                    [35.75958930755259,139.56095836493682],
            ], {color: "#72a113aa", weight: 20})
            , "伏見通り", "Fushimi-dori Ave.", [35.721398893775046,139.55739831044343], "#72a113", "ふしみ"],

            "route_Tohachi": [
                L.polyline([
                    [35.68565740620396,139.59147871354878],
                    [35.6855540084147,139.59098163308818],
                    [35.68408180635544,139.58877508075796],
                    [35.68368790152226,139.58813251335573],
                    [35.683072421335766,139.58676857309024],
                    [35.68275236975687,139.5854955621146],
                    [35.682540642606654,139.58307077929442],
                    [35.68238800176904,139.58118551066468],
                    [35.68170135276608,139.57653638279214],
                    [35.68127789236635,139.573669077183],
                    [35.68106616131546,139.57194141947605],
                    [35.68105138935037,139.5711776128827],
                    [35.68110062916335,139.57006827477335],
                    [35.68115479293667,139.56798902354046],
                    [35.681272968317415,139.56433366347756],
                    [35.68114446169302,139.55784391286443],
                    [35.68121339736459,139.5564981584364],
                    [35.6814054321313,139.55454620830665],
                    [35.681715641168864,139.55049682106414],
                    [35.68185351147649,139.54867823395762],
                    [35.681838739672656,139.54765376325804],
                    [35.68174518481284,139.54612008818776],
                    [35.6812117259813,139.53869074744716],
                    [35.68168771058252,139.53037483432217],
                    [35.685367842098785,139.52330377797398],
                    [35.686722688617195,139.52100736555417],
                    [35.687338140593056,139.5190493534385],
                    [35.68910300671034,139.5095207535577],
                    [35.68929009902284,139.50812650345893],
                    [35.689467707730685,139.49832803046263],
                    [35.68945786078905,139.49661855858494],
                    [35.68922153405191,139.49535767155135],
                    [35.68853224375066,139.49332691592872],
                    [35.68797096009369,139.49173868320963],
                    [35.68788725955059,139.4903686809398],
                    [35.68856449556323,139.48227261828902],
                    [35.68858911316349,139.48136332471393],
                    [35.68858294069702,139.47605988045927],
                    [35.688494317260506,139.47473837391328],
                    [35.68834168778915,139.4733683716338],
                    [35.68739636341492,139.46618495257349],
                    [35.68696800962188,139.4637419838857],
                    [35.68594098252376,139.4595493251768],
                    [35.68510888873253,139.4563192749972],
                    [35.68415860227451,139.4552463086245],
                    [35.68328216223968,139.45480378578668],
                    [35.68223337584273,139.45411878467462],
                    [35.68083497252107,139.45343378354232],
                    [35.680066825066774,139.45292457917253],
                    [35.678988451730376,139.45172431173341],
                    [35.67758999155011,139.45009970729166],
            ], {color: "#67db56aa", weight: 20})
            , "東八道路", "Tohachi-doro Ave.", [35.68565740620396,139.59147871354878], "#67db56", "とうはち"],

            "route_Renjaku": [
                L.polyline([
                    [35.687503632084216,139.57965794428412],
                    [35.687816604793525,139.57869222788497],
                    [35.688581644034244,139.57718418799914],
                    [35.68927326411374,139.57590925205466],
                    [35.69003828938169,139.57421092320578],
                    [35.69081103473742,139.5721462881171],
                    [35.690938536975324,139.5712091150533],
                    [35.69124376882549,139.56931574001953],
                    [35.692062886121164,139.5646907319541],
                    [35.69304474590673,139.55936604191697],
                    [35.69367871048196,139.55605645450441],
                    [35.69443926829214,139.55265167847574],
                    [35.69474448673661,139.551234025859],
                    [35.69484493812378,139.55053947120587],
                    [35.69505206840479,139.54562540791892],
                    [35.695148655907815,139.5426015125429],
                    [35.695254487852864,139.5378381083658],
                    [35.695142446477234,139.535854346002],
                    [35.694917373331336,139.5328565846021],
                    [35.694728061135564,139.5305303022185],
                    [35.694391934296306,139.52769975413776],
                    [35.69437228464011,139.52370588841995],
                    [35.694399329372274,139.5196776580052],
                    [35.694588841621865,139.51836893070845],
                    [35.69457725103152,139.51790272276685],
                    [35.69472406486932,139.5172557403505],
                    [35.6956702449268,139.51409879750736],
                    [35.695786149020606,139.513456572324],
                    [35.69606431816793,139.51285240491472],
                    [35.69689495637613,139.51142048057636],
                    [35.69755173393921,139.5099933134402],
                    [35.698709429709,139.50634632513902],
                    [35.69917673001143,139.50147897208797],
                    [35.699350578426404,139.49930016368182],
                    [35.69967895777066,139.49781115265955],
                    [35.69984894182774,139.49668369066217],
                    [35.699991882691364,139.49529933856275],
                    [35.70037820809014,139.49309198677406],
                    [35.7010813155146,139.49298257064402],
                    [35.701517857110844,139.49128424182868],
                    [35.701861680214975,139.49065628830476],
                    [35.702054838615965,139.49064201665362],
                    [35.70226731231507,139.4899855197998],
                    [35.70297426619314,139.48879145668138],
                    [35.70381255857217,139.48722633009118],
                    [35.70458903350577,139.48558984514807],
                    [35.70511054226385,139.48445762590916],
                    [35.70577180906383,139.48308022463357],
                    [35.70623150273269,139.48213353712822],
                    [35.70811363523403,139.47810396413684],
                    [35.71031719431092,139.4734282207305],
                    [35.71132032395256,139.47073082084853],
                    [35.71217011658115,139.46843783905297],
                    [35.71262977336279,139.46722950420963],
            ], {color: "#f07c51aa", weight: 20})
            , "連雀通り", "Renjaku-dori Ave.", [35.687503632084216,139.57965794428412], "#f07c51", "れんじゃく"],
        }

        routes = Object.entries(routes)

        routes.sort(
            (route1, route2) => {
                return route1[1][5].localeCompare(route2[1][5])
            }, "ja"
        )

        routes = Object.fromEntries(routes)

        for (const route in routes) {
            routes[route][0].addTo(map).on("click",
                () => {
                    this.routeNameJp = routes[route][1]
                    this.routeNameEn = routes[route][2]
                    elements.route_bg.style.animation = "fadeIn-bg 1.0s forwards"
                    elements.route.style.animation = "fadeIn 1.0s forwards"
                }
            )
            const element_route = document.createElement("div")
            element_route.textContent = routes[route][1]
            element_route.setAttribute("route-object-name", route)
            element_route.classList.add("route-button")
            elements.menu_contents.appendChild(element_route)
        }
        elements.route_buttons = document.querySelectorAll(".route-button")

        elements.close_button.addEventListener("click",
            () => {
                this.routeNameJp = ""
                this.routeNameEn = ""
                elements.route_bg.style.animation = "fadeOut-bg 1.0s forwards"
                elements.route.style.animation = "fadeOut 1.0s forwards"
            }
        )

        elements.menu_button.addEventListener("click",
            () => {
                if(elements.menu_contents.getAttribute("available") === "1") {
                    elements.menu_contents.style.animation = "fadeOut-menu 2.0s forwards"
                    elements.menu_button.setAttribute("available", "0")
                    elements.menu_contents.setAttribute("available", "0")
                } else {
                    elements.menu_contents.style.animation = "fadeIn-menu 1.0s forwards"
                    elements.menu_button.setAttribute("available", "1")
                    elements.menu_contents.setAttribute("available", "1")
                }
            }
        )

        elements.all_route_button.addEventListener("click",
            () => {
                for (const route in routes) {
                    routes[route][0].addTo(map).on("click",
                        () => {
                            this.routeNameJp = routes[route][1]
                            this.routeNameEn = routes[route][2]
                            elements.route_bg.style.animation = "fadeIn-bg 1.0s forwards"
                            elements.route.style.animation = "fadeIn 1.0s forwards"
                        }
                    )
                }
                if(this.routeNameJp !== "") {
                    elements.route_bg.style.animation = "fadeOut-bg 1.0s forwards"
                    elements.route.style.animation = "fadeOut 1.0s forwards"
                }
                this.routeNameJp = ""
                this.routeNameEn = ""
            }
        )

        elements.route_buttons.forEach( (route_button) => {
            const chosen_route = route_button.getAttribute("route-object-name")
            route_button.addEventListener("click",
                () => {
                    for (const route in routes) {
                        routes[route][0].remove(map)
                    }
                    routes[chosen_route][0].addTo(map).on("click",
                        () => {
                            this.routeNameJp = routes[chosen_route][1]
                            this.routeNameEn = routes[chosen_route][2]
                            elements.route_bg.style.animation = "fadeIn-bg 1.0s forwards"
                            elements.route.style.animation = "fadeIn 1.0s forwards"
                        }
                    )
                    map.setView(routes[chosen_route][3])
                    if(this.routeNameJp === "") {
                        elements.route_bg.style.animation = "fadeIn-bg 1.0s forwards"
                        elements.route.style.animation = "fadeIn 1.0s forwards"
                    }
                    this.routeNameJp = routes[chosen_route][1]
                    this.routeNameEn = routes[chosen_route][2]
                }
            )
            route_button.style.borderLeft = `2vh solid ${routes[chosen_route][4]}`
        })

        elements.info_button.addEventListener("click",
            () => {
                elements.info_contents.style.animation = "fadeIn-info 1.0s forwards"
                elements.modal_bg.style.animation = "fadeIn-modal-bg 1.0s forwards"
            }
        )

        elements.info_close_button.addEventListener("click",
            () => {
                elements.info_contents.style.animation = "fadeOut-info 1.0s forwards"
                elements.modal_bg.style.animation = "fadeOut-modal-bg 1.0s forwards"
            }
        )

        document.addEventListener("DOMContentLoaded", () => {
            if (Number(window.innerHeight) > Number(window.innerWidth)) {
                elements.subtitle.style.display ="none"
            }
            else {
                elements.subtitle.style.display = "inline"
            }
        })

        window.addEventListener('load', function() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/pwa/service-worker.js');
            }
        });
    }
}
</script>

<style>
@import url(https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap);
@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
html, body, #app {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
body {
    margin: 0;
    font-family: "Kosugi Maru", sans-serif;
}
#map {
    height: 90%;
    width: 100%;
    position: absolute;
    top: 10%;
    left: 0;
}
#header {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2vh);
    height: 8vh;
    z-index: 1000;
    background: white;
    font-size: 4vh;
    line-height: 6vh;
    padding: 1vh;
    border-bottom: 2px solid gray;
}
#header a:link, a:visited, a:hover, a:active {
  color: #000000;
  text-decoration: none;
}
#header h1 {
    display: inline;
    font-size: 4vh;
}
#header #subtitle {
    font-size: 60%;
    margin: 0 0 0 3ch;
}
#header #menu {
    display: inline-block;
    position: absolute;
    top: 2vh;
    left: calc(100% - 10vw);
    font-size: 4vh;
    color: #737578;
    cursor: pointer;
}
#header #menu:hover, #header #menu[available="1"] {
    color: #0000ff;
}
#route-bg {
    height: 20vh;
    width: 100%;
    background: #ffffff;
    position: absolute;
    z-index: -2;
    top: calc(100% - 20vh);
    left: 0;
    text-align: center;
    opacity: 0;
}
#route {
    height: 12vh;
    width: 60vw;
    border: solid 2vh #0000ff;
    background: #ffffff;
    position: absolute;
    z-index: -1;
    top: calc(100% - 18vh);
    left: calc(50% - 30vw);
    font-size: 4vh;
    text-align: center;
    line-height: 6vh;
    opacity: 0;
}
#route:before {
    position: absolute;
    content: "";
    z-index: 1;
    top: calc(100% - 14vh);
    right: calc(60vw + 1.8vh);
    width: 0px;
    height: 0px;
    border-width: 8vh 5vh 8vh 0vh;
    border-color: transparent #0000ff transparent transparent;
    border-style: solid;
}
#route:after {
    position: absolute;
    content: "";
    z-index: 1;
    top: calc(100% - 14vh);
    left: calc(60vw + 1.8vh);
    width: 0px;
    height: 0px;
    border-width: 8vh 0vh 8vh 5vh;
    border-color: transparent transparent transparent #0000ff;
    border-style: solid;
}
#route-en {
    font-size: 60%;
    display: block;
}
#close-button {
    display: inline-block;
    position: absolute;
    top: calc(100% - 20vh + 2vh);
    left: calc(100% - 10vw);
    font-size: 4vh;
    color: #737578;
    cursor: pointer;
}
#close-button:hover {
    color: #0000ff;
}
#menu-contents {
    width: 60vmin;
    height: calc(100% - 10vh - 20vh - 4px);
    position: absolute;
    top: 10vh;
    right: -100%;
    z-index: 10001;
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 2px solid gray;
}
#menu-contents #info {
    display: inline-block;
    font-size: 4vh;
    color: #737578;
    margin: 2vh;
    cursor: pointer;
}
#menu-contents #info:hover {
    color: #0000ff;
}
#menu-contents #all-route-button {
    font-size: 3vh;
    margin: 1.5vh;
    cursor: pointer;
}
#menu-contents #all-route-button:hover {
    color: #0000ff;
}
#menu-contents .route-button {
    margin: 1.5vh;
    font-size: 3vh;
    cursor: pointer;
}
#menu-contents .route-button:hover {
    color: #0000ff;
}
#info-contents {
    width: 60%;
    height: 40%;
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: -3;
    background: #ffffff;
    padding: 5%;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 2px solid gray;
    word-wrap: break-word;
}
#info-contents h2 {
    margin: 0 0 10vmin 0;
}
#info-contents h3 {
    margin: 0 0 5vmin 0;
}
#info-contents #close-button-area {
    text-align: right;
}
#info-contents #info-close {
    font-size: 4vh;
    color: #737578;
    cursor: pointer;
}
#info-contents #info-close:hover {
    color: #0000ff;
}
#modal-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -4;
    background: #808080;
    padding: 5%;
    opacity: 0;
    overflow: scroll;
}
@keyframes fadeIn-bg {
    0% {
        opacity: 0;
        z-index: -2;
    }
    100% {
        opacity: 0.6;
        z-index: 9999;
    }
}
@keyframes fadeOut-bg {
    0% {
        opacity: 0.6;
        z-index: 9999;
    }
    100% {
        opacity: 0;
        z-index: -2;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        z-index: -1;
    }
    100% {
        opacity: 1;
        z-index: 10000;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
        z-index: 10000;
    }
    100% {
        opacity: 0;
        z-index: -1;
    }
}
@keyframes fadeIn-menu {
    0% {
        right: -100%;
    }
    100% {
        right: 0;
    }
}
@keyframes fadeOut-menu {
    0% {
        right: 0;
    }
    100% {
        right: -100%;
    }
}
@keyframes fadeIn-info {
    0% {
        opacity: 0;
        z-index: -3;
    }
    100% {
        opacity: 1;
        z-index: 20000;
    }
}
@keyframes fadeOut-info {
    0% {
        opacity: 1;
        z-index: 20000;
    }
    100% {
        opacity: 0;
        z-index: -3;
    }
}
@keyframes fadeIn-modal-bg {
    0% {
        opacity: 0;
        z-index: -4;
    }
    100% {
        opacity: 0.7;
        z-index: 19999;
    }
}
@keyframes fadeOut-modal-bg {
    0% {
        opacity: 0.7;
        z-index: 19999;
    }
    100% {
        opacity: 0;
        z-index: -4;
    }
}
</style>
